import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  BaseSyntheticEvent,
} from "react";

import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Checkbox, message, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import axios from "axios";
import postCheckout from "../../api/postCheckout";
import setCartAndUserListsToStore from "../../state/actions/setCartAndUserListsToStore";
import requestCatchHandler from "../../api/requestCatchHandler";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import TotalSum from "./TotalSum";
import showCartErrorMessage from "./showCartErrorMessage";
import { messageData, routePathNames } from "../../appConfig";
import {
  CART_ERROR_MESSAGE_MISMATCH,
  CART_ERROR_MESSAGE_LOADING,
  CART_ERROR_MESSAGE_VALIDATION,
  CART_ERROR_CODE_VALIDATION,
  CART_ERROR_CODE_ORDER_PLACEMENT,
} from "./cartErrorConfig";
import { RootState } from "../../types/rootState";
import { CheckoutData } from "../../types/checkoutData";
import handleError from "../../utils/handleError";

interface Props {
  totalSelectedItems: number;
  selectedItemsTotal: number;
  mobileView?: boolean;
  isAlwaysVisible?: boolean;
  termsAndConditionsAccepted?: boolean;
  onTermsAndConditionsChange?: (accepted: boolean) => void;
  onUnavailableCartItemsError?: () => void;
  disabled?: boolean;
  itemsTotal?: number;
  totalItems?: number;
  checkoutButtonText?: string;
  onIsLoadingChange?: (isLoading: boolean) => void;
}

const CartCheckout: React.FC<Props> = ({
  selectedItemsTotal,
  totalSelectedItems,
  mobileView,
  isAlwaysVisible = false,
  termsAndConditionsAccepted = true,
  onTermsAndConditionsChange,
  onUnavailableCartItemsError,
  disabled = false,
  itemsTotal,
  totalItems,
  checkoutButtonText = "Zahlungspflichtig bestellen",
  onIsLoadingChange,
}: Props) => {
  const navigate = useNavigate();

  const checkoutButtonRef = useRef<HTMLButtonElement>(null);

  // redux
  const { email, firstName, lastName, salutation } = useSelector(
    (state: RootState) => state.userData
  );
  const {
    id: cartId,
    deliveryDate,
    companyBusinessUnitKey: companyBusinessUnitKeyCurrentCartMeta,
  } = useSelector((state: RootState) => state.currentCartMetaData);
  const {
    cartId: cartIdCurrentCart,
    cartItems,
    selectedCartItems,
    companyBusinessUnitKey: companyBusinessUnitKeyCurrentCart,
  } = useSelector((state: RootState) => state.currentCart);
  const { companyBusinessUnitKey } = useSelector(
    (state: RootState) => state?.userData?.businessUnit
  );

  // local states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [partialOrderModalVisible, setPartialOrderModalVisible] =
    useState<boolean>(false);
  const [hasUnavailableCartItemsError, setHasUnavailableCartItemsError] =
    useState<boolean>(false);

  const cancelTokenSource = useRef(getCancelTokenSource());

  const checkoutData: CheckoutData = useMemo(
    () => ({
      cartId,
      customer: {
        salutation,
        email,
        firstName,
        lastName,
      },
      payments: [
        {
          paymentMethodName: "invoice",
          paymentProviderName: "WeilingOrder",
        },
      ],
      shipment: {
        idShipmentMethod: 1,
      },
      partialOrderSkus: selectedCartItems,
      companyBusinessUnitKey,
    }),
    [
      cartId,
      selectedCartItems,
      salutation,
      email,
      firstName,
      lastName,
      companyBusinessUnitKey,
    ]
  );

  /**
   * checkout handler to trigger order
   */
  const checkoutHandler = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const isCartOwnedByCompanyBusinessUnit =
      companyBusinessUnitKey === companyBusinessUnitKeyCurrentCart &&
      companyBusinessUnitKey === companyBusinessUnitKeyCurrentCartMeta;

    const isInvalidCompanyBusinessUnit =
      companyBusinessUnitKeyCurrentCart &&
      companyBusinessUnitKeyCurrentCartMeta &&
      !isCartOwnedByCompanyBusinessUnit;

    if (isInvalidCompanyBusinessUnit) {
      const errorMessage = String(CART_ERROR_MESSAGE_MISMATCH)
        .replace("%cartId%", cartId)
        .replace("%companyBusinessUnitKey%", companyBusinessUnitKey);
      showCartErrorMessage(errorMessage);
      handleError(errorMessage);
      setIsLoading(false);
      return;
    }

    if (cartId !== cartIdCurrentCart) {
      showCartErrorMessage(CART_ERROR_MESSAGE_LOADING);
      setIsLoading(false);
      return;
    }

    postCheckout(checkoutData, cancelTokenSource.current)
      .then(() =>
        setCartAndUserListsToStore({
          deliveryDate,
          cancelTokenSource: cancelTokenSource.current,
        })
      )
      .then(() => {
        navigate(`${routePathNames.orderConfirmation}`, {
          state: {
            remainingItemsInCart: cartItems.length - selectedCartItems.length,
            redirectToShop: false,
          },
        });
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        const { status } = error?.response || {};
        const { errors = [] } = error?.response?.data || {};
        const { code, detail } = errors?.[0] || {};

        if (!axios.isCancel(error)) {
          setIsLoading(false);
        }

        // Validation error
        if (status === 422 && code === CART_ERROR_CODE_VALIDATION) {
          showCartErrorMessage(CART_ERROR_MESSAGE_VALIDATION);
        }

        // Order placement error
        if (status === 422 && code === CART_ERROR_CODE_ORDER_PLACEMENT) {
          if (detail.includes("Teilbestellungen")) {
            message.info(messageData.error.cart.unavailableItem);
            setHasUnavailableCartItemsError(true);
          } else {
            showCartErrorMessage(detail);
          }
        }

        // Other error (i.e. server not responding)
        if (status !== 422) {
          message.error(messageData.error.checkoutFailure);
        }

        requestCatchHandler(error);
      });
  };

  /**
   * handler for the order button
   */
  const buttonOnClick = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (selectedCartItems?.length < cartItems?.length) {
      setPartialOrderModalVisible(true);
    } else {
      checkoutHandler();
    }
  };

  /**
   * toggle state for terms and conditions
   * @param event {CheckboxChangeEvent}
   */
  const checkboxOnChange = (event: CheckboxChangeEvent) => {
    onTermsAndConditionsChange(event.target.checked);
  };

  /**
   * Handle unavailable cart items error (=> run callback)
   */
  useEffect(() => {
    if (hasUnavailableCartItemsError) {
      onUnavailableCartItemsError();
      setHasUnavailableCartItemsError(false);
    }
    return () => {};
  }, [hasUnavailableCartItemsError, onUnavailableCartItemsError]);

  useEffect(() => {
    if (typeof onIsLoadingChange === "function") {
      onIsLoadingChange(isLoading);
    }
  }, [isLoading, onIsLoadingChange]);

  return (
    <>
      <TotalSum
        totalSelectedItems={totalSelectedItems}
        selectedItemsTotal={selectedItemsTotal}
        width={{ lg: 5, xs: 12 }}
        className={clsx(
          // eslint-disable-next-line no-nested-ternary
          isAlwaysVisible ? "" : mobileView ? "hidden-lg-up" : "hidden-md-down"
        )}
        id={mobileView && "mobile-order"}
        itemsTotal={itemsTotal}
        totalItems={totalItems}
      >
        <div className="cart-total-row checkbox-div hidden-print">
          <Checkbox
            checked={termsAndConditionsAccepted}
            onChange={checkboxOnChange}
            className={clsx(!termsAndConditionsAccepted && "agb-not-checked")}
          />

          <p
            className={clsx(
              "agb",
              !termsAndConditionsAccepted && "agb-not-checked"
            )}
          >
            Es gelten unsere{" "}
            <Link to={routePathNames.termsAndConditions}>AGB</Link>. Bitte
            beachten Sie die{" "}
            <Link to={routePathNames.privacy}>Datenschutzbestimmungen</Link>.
          </p>
        </div>
        <Button
          disabled={
            isLoading ||
            disabled ||
            !termsAndConditionsAccepted ||
            !selectedCartItems?.length
          }
          ref={checkoutButtonRef}
          loading={isLoading}
          className="button buttonPrimary width-full hidden-print"
          onClick={buttonOnClick}
        >
          {checkoutButtonText}
        </Button>
      </TotalSum>

      <Modal
        title="ACHTUNG!"
        visible={partialOrderModalVisible}
        closable
        maskClosable={false}
        confirmLoading={isLoading}
        onOk={checkoutHandler}
        okText="Bestellung fortsetzen"
        onCancel={() => setPartialOrderModalVisible(false)}
        cancelText="Abbrechen"
      >
        <p>
          Du hast nur {selectedCartItems.length} von {cartItems.length}{" "}
          Produkten im Warenkorb für die Bestellung ausgewählt. Willst du mit
          der Bestellung fortfahren?
        </p>
      </Modal>
    </>
  );
};

export default CartCheckout;
