import React from "react";

import clsx from "clsx";

interface PictureTileProps {
  icon?: React.ReactNode;
  picture: React.ReactNode;
  link: React.ReactNode;
  title: string;
  className?: string;
  overlayPosition?: "bottom" | "right" | "hidden";
}

const PictureTile: React.FC<PictureTileProps> = (props) => {
  const {
    icon,
    picture,
    link,
    title,
    className,
    overlayPosition = "bottom",
  } = props;

  return (
    <div
      className={clsx(
        "picture-tile",
        overlayPosition && `picture-tile--overlay-${overlayPosition}`,
        className
      )}
    >
      {!!icon && <div className="picture-tile__icon">{icon}</div>}
      <div className="picture-tile__thumbnail">{picture}</div>
      <div className="picture-tile__overlay picture-tile__quick-link">
        <div className="picture-tile__overlay__content">
          <span className="picture-tile__overlay__title">{title}</span>
          <span className="picture-tile__overlay__link">{link}</span>
        </div>
      </div>
    </div>
  );
};

export default PictureTile;
