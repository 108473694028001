import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Button, Checkbox, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Entry } from "contentful";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useGetPortalTip from "../../../hooks/useGetPortalTip";
import renderContentfulDocument from "../../../utils/renderContentfulDocument";
import HrDivider from "../../divider/Divider";
import { ContentfulPortalTipEntry } from "../../../types/contentfulPortalTipEntry";
import { RootState } from "../../../types/rootState";
import { routePathNames } from "../../../appConfig";

interface PortalTipModalProps {
  className?: string;
}

const PortalTipModal: React.FC<PortalTipModalProps> = (
  props: PortalTipModalProps
) => {
  const { className } = props;

  const dispatch = useDispatch();
  const { isLoading, hasError, entries } = useGetPortalTip({ limit: 1 });

  const { showPortalTips = true } =
    useSelector((state: RootState) => state?.userData) || {};
  const { hiddenTipIds = [] } =
    useSelector((state: RootState) => state?.portalTip) || {};

  const [tip, setTip] = useState<Entry<ContentfulPortalTipEntry>>();
  const [shouldHideTip, setShouldHideTip] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleHideTip = (e: CheckboxChangeEvent) => {
    const isChecked = e?.target?.checked || false;

    setShouldHideTip(isChecked);

    if (!isChecked) {
      dispatch({
        type: "portal-tip/un-hide-tip-by-id",
        payload: { tipId: tip?.sys?.id },
      });
      return;
    }

    dispatch({
      type: "portal-tip/hide-tip-by-id",
      payload: { tipId: tip?.sys?.id },
    });
  };

  useEffect(() => {
    const isTipValid =
      entries?.length > 0 && !hiddenTipIds.includes(entries?.[0]?.sys?.id);

    if (!isTipValid) {
      return;
    }

    setTip(entries?.[0] || null);
    setIsModalVisible(true);
  }, [entries, hiddenTipIds]);

  if (!showPortalTips || isLoading || hasError || !tip) {
    return null;
  }

  return (
    <Modal
      className={clsx("portal-tip-modal", className)}
      width={900}
      cancelButtonProps={{ style: { display: "none" } }}
      visible={isModalVisible}
      onCancel={handleClose}
      closable
      destroyOnClose
      footer={
        <>
          <Button
            type="primary"
            className="button buttonPrimary button--rounded"
            onClick={handleClose}
          >
            Schließen
          </Button>
          <div>
            {"Interesse an weiteren Tipps? "}
            <Link to={routePathNames.support} onClick={handleClose}>
              Zum Tipp-Archiv
            </Link>
          </div>
        </>
      }
    >
      <Checkbox checked={shouldHideTip} onChange={handleHideTip}>
        Diesen Tipp nicht mehr anzeigen
      </Checkbox>
      <HrDivider spacingBottom="sm" spacingTop="m" />
      <h4>
        <span>Tipp der Woche:</span>
        {` ${tip?.fields?.headline || ""}`}
      </h4>
      <div className="portal-tip-modal__body">
        {renderContentfulDocument(tip?.fields?.content)}
      </div>
    </Modal>
  );
};

export default PortalTipModal;
