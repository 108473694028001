import React, { createRef } from "react";

import clsx from "clsx";
import { Col, Layout, Row, Typography } from "antd";

import ButtonBackToTop from "../../buttons/ButtonBackToTop";
import SupportIntro from "./SupportIntro";
import SupportContact from "./SupportContact";
import SupportClient from "./SupportClient";
import SupportFaq from "./SupportFaq";
import SupportPortalTips from "./SupportPortalTips";
import SupportTutorials from "./SupportTutorials";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";

const { Title } = Typography;

interface SupportProps {
  className?: string;
}

const Support: React.FC<SupportProps> = (props: SupportProps) => {
  const { className } = props;

  const tutorialsRef = createRef<HTMLDivElement>();
  const faqRef = createRef<HTMLDivElement>();
  const tipsRef = createRef<HTMLDivElement>();
  const clientRef = createRef<HTMLDivElement>();
  const contactRef = createRef<HTMLDivElement>();

  return (
    <>
      <TrackingHelmet title={pageTitles.support} suffix={pageTitleSuffix} />

      <div className={clsx("support-page", className)}>
        <Layout className="container-layout container-layout--inner">
          <Row>
            <Col span={12}>
              <Title>Hilfe & Support</Title>
            </Col>
          </Row>

          <SupportIntro
            tutorialsRef={tutorialsRef}
            faqRef={faqRef}
            tipsRef={tipsRef}
            contactRef={contactRef}
          />

          <SupportTutorials ref={tutorialsRef} />

          <SupportFaq ref={faqRef} />

          <SupportPortalTips ref={tipsRef} />

          <SupportClient ref={clientRef} />
        </Layout>

        <SupportContact ref={contactRef} />

        <ButtonBackToTop />
      </div>
    </>
  );
};

export default Support;
