import { CombinedState, combineReducers, Reducer } from "redux";
import storage from "redux-persist/lib/storage";
// Import all Reducers here
import purgeStoredState from "redux-persist/es/purgeStoredState";
import userDataReducer from "./userDataReducer";
import currentCartItemsReducer from "./currentCartItemsReducer";
import currentCartMetaDataReducer from "./currentCartMetaDataReducer";
import podcastReducer from "./podcastReducer";
import moodleReducer from "./moodleReducer";
import productsMetaDataReducer from "./productsMetaDataReducer";
import weekplannerDataReducer from "./weekplannerDataReducer";
import networkActivityReducer from "./networkActivityReducer";
import dailyOrdersReducer from "./dailyOrdersReducer";
import categoryNavigationReducer from "./categoryNavigationReducer";
import infoCenterReducer from "./infoCenterReducer";
import newsReducer from "./newsReducer";
import authReducer from "./authReducer";
import priceTagListReducer from "./priceTagListReducer";
import favouriteListReducer from "./favouriteListReducer";
import pageReducer from "./pageReducer";
import termsAndConditionsReducer from "./termsAndConditionsReducer";
import alreadyOrderedReducer from "./alreadyOrderedReducer";
import sidebarReducer from "./sidebarReducer";
import notificationModalReducer from "./notificationModalReducer";
import appSettingsReducer from "./appSettingsReducer";
import impulseReducer from "./impulseReducer";
import quickOrderReducer from "./quickOrderReducer";
import { RootState } from "../../types/rootState";
import searchReducer from "./searchReducer";
import portalTipReducer from "./portalTipReducer";

// Declare all reducers here
const appReducer: Reducer<CombinedState<RootState>, any> = combineReducers({
  auth: authReducer,
  userData: userDataReducer,
  currentCart: currentCartItemsReducer,
  dailyOrders: dailyOrdersReducer,
  alreadyOrdered: alreadyOrderedReducer,
  currentCartMetaData: currentCartMetaDataReducer,
  podcasts: podcastReducer,
  moodle: moodleReducer,
  productsMetaData: productsMetaDataReducer,
  weekplannerData: weekplannerDataReducer,
  networkActivity: networkActivityReducer,
  categoryNavigation: categoryNavigationReducer,
  infoCenter: infoCenterReducer,
  news: newsReducer,
  priceTagList: priceTagListReducer,
  favouriteList: favouriteListReducer,
  page: pageReducer,
  termsAndConditions: termsAndConditionsReducer,
  sidebar: sidebarReducer,
  notificationModal: notificationModalReducer,
  appSettings: appSettingsReducer,
  impulse: impulseReducer,
  quickOrder: quickOrderReducer,
  search: searchReducer,
  portalTip: portalTipReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "store/clear") {
    const newState: CombinedState<RootState> = {
      quickOrder: state?.quickOrder || {},
      portalTip: state?.portalTip || {},
    } as CombinedState<RootState>;
    purgeStoredState({ key: "primary", storage });
    return appReducer(newState, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
