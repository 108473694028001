import React from "react";

import { Link } from "react-router-dom";
import { Badge } from "antd";
import { useDispatch } from "react-redux";
import { locationSearchQueryParameter, routePathNames } from "../../appConfig";
import { Brand } from "../../types/brand";
import HrDivider from "../divider/Divider";

interface Props {
  query: string;
  brand: Record<string, any>;
  context?: "productlist" | "weekplanner";
}

const BrandSuggestion = (props: Props): any => {
  const { brand, context = "productlist", query } = props;

  const dispatch = useDispatch();

  const getItemTargetUrl = (name: string, filterUrlParam: string) => {
    const basePath =
      context === "weekplanner"
        ? routePathNames.weekPlanner
        : routePathNames.products;
    return `${basePath}${
      routePathNames.search
    }?${filterUrlParam}=${encodeURIComponent(name)}&${
      locationSearchQueryParameter.searchTerm
    }=${query}`;
  };

  // Custom sorting function
  const customSort = (a: Brand, b: Brand) => {
    // If the brandName is 'bioladen', it should come before others
    if (a.brandName === "bioladen" && b.brandName !== "bioladen") {
      return -1; // a comes first
    }
    if (a.brandName !== "bioladen" && b.brandName === "bioladen") {
      return 1; // b comes first
    }
    // If both have the same brandName or both are not 'bioladen', sort by amount
    return b.amount - a.amount;
  };
  return (
    <div className="brandWrapper">
      <div className="brand__title suggest-title">
        <span>Marke</span>
      </div>
      <div className="brand__body suggest-body">
        {brand
          .sort(customSort)
          .slice(0, 5)
          .map((brandItem: Brand) => {
            return (
              <div
                className="suggest-item-container"
                key={`${brandItem.brandName}_${brandItem.amount}`}
              >
                <Link
                  to={getItemTargetUrl(
                    brandItem.brandName,
                    "herstellermarkenpreislistentextfuerausgabe"
                  )}
                  onClick={() => {
                    dispatch({
                      type: "search/set-search-query",
                      payload: query || "",
                    });
                  }}
                >
                  <div className="brand__item suggest-item">
                    <span>{brandItem.brandName}</span>
                    <Badge
                      count={brandItem.amount}
                      // be sure that the ant class is the last one due to breaking the component behavior otherwise
                      className="badge-suggestion-count"
                      title={String(brandItem.amount)}
                    />
                  </div>
                </Link>
              </div>
            );
          })}
        <HrDivider />
      </div>
    </div>
  );
};

export default BrandSuggestion;
