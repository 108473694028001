import axiosWithAuth from "../axiosWithAuth";
import { customerEndpoint } from "../endpoints";
import { CompanyUserProfile } from "../../types/companyUserProfile";
import aggregateCompanyUserData from "../../utils/aggregateCompanyUserData";

/**
 * PATCH / update company User at spryker
 * return new user data
 * @param userData {Object}
 * @return {Promise<AxiosResponse|Record<any,any>[]>}
 */

const patchCompanyUser = async (userData: CompanyUserProfile) => {
  const {
    firstName,
    lastName,
    phone,
    email,
    isSuperAdmin,
    salutation,
    id,
    newsPreferences,
    favouriteListDefaultSorting,
    deliveryDates,
    showPortalTips,
  } = userData;
  const requestBody = {
    data: {
      type: "customers",
      attributes: {
        firstName,
        lastName,
        phone,
        email,
        isSuperAdmin,
        salutation: salutation === "" ? null : salutation,
        newsPreferences,
        favouriteListDefaultSorting,
        deliveryDates,
        showPortalTips,
      },
    },
  };

  return axiosWithAuth()
    .patch(`${customerEndpoint}/${id}`, requestBody)
    .then((response) => {
      if (response?.status > 200) {
        return Promise.reject(response);
      }
      const { data } = response.data;
      if (data) {
        // create a new array with needed userdate
        return aggregateCompanyUserData([data], {})[0];
      }
      return [{}];
    });
};

export default patchCompanyUser;
