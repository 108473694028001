import React, { useMemo } from "react";

import clsx from "clsx";

import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import getDeliveryDateBasedAttributes from "./getDeliveryDateBasedAttributes";
import getCheapestPrice from "./getCheapestPrice";
import parseProductAttributes from "./parseProductAttributes";
import Price from "../price/Price";
import { ProductData } from "../../types/productData";
import getProductBasePrice from "../../utils/getProductBasePrice";
import isProductFruitOrVegetable from "../../utils/isProductFruitOrVegetable";
import getProductUVP from "../../utils/getProductUVP";
import { RootState } from "../../types/rootState";

interface Props {
  productUnitQuantity: string;
  productUnitMeasurement: string;
  prices?: ProductData["prices"];
  deliveryDate?: string;
  basePriceFactor?: string;
  basePriceUnit?: string;
  weighingArticle?: string;
  quantity?: number;
  className?: string;
  showProductUVP?: boolean;
  sku?: string;
  vatGroup?: string;
  basePriceQuantity?: string;
}

const ProductPackageInfo: React.FC<Props> = ({
  productUnitMeasurement,
  productUnitQuantity,
  prices,
  basePriceFactor,
  basePriceUnit,
  className,
  deliveryDate,
  quantity,
  weighingArticle,
  showProductUVP = false,
  sku = "",
  vatGroup = "",
  basePriceQuantity = "1",
}: Props) => {
  const { price, volumePrices, rrPrice } = getDeliveryDateBasedAttributes({
    deliveryDate,
    prices,
  });

  // determine the cheapest price based on quantity and volumes
  const cheapestPrice: number = getCheapestPrice(price, volumePrices, quantity);

  const productBasePrice: number = getProductBasePrice(
    cheapestPrice,
    parseFloat(productUnitQuantity),
    parseFloat(basePriceFactor),
    basePriceUnit,
    productUnitMeasurement,
    weighingArticle
  );

  const hidePrices = useSelector(
    (state: RootState) => state?.appSettings?.hidePrices || false
  );

  const hasBasePriceUnit = useMemo(
    () => (basePriceUnit ? !!String(basePriceUnit).trim() : false),
    [basePriceUnit]
  );

  const isFruitOrVegetableProduct = isProductFruitOrVegetable(sku);

  const productUVP = getProductUVP(
    rrPrice,
    productUnitQuantity,
    weighingArticle === "1"
  );

  const basePriceQuantityNumber: number = parseFloat(basePriceQuantity);

  const productPrice =
    weighingArticle === "1"
      ? cheapestPrice
      : cheapestPrice / basePriceQuantityNumber;

  const calcTradingMargin = (netRRP: number, articlePrice: number) => {
    const grossRRP =
      netRRP / (1 + Number(vatGroup.replace("%", "").replace(" ", "")) / 100);
    return Math.round((grossRRP / articlePrice) * 100 - 100);
  };

  const tradingMargin = calcTradingMargin(productUVP, productPrice);

  if (productUnitQuantity && productUnitMeasurement) {
    const unitQuantity = parseProductAttributes(productUnitQuantity);
    return (
      <>
        {className === "cart-package-info" && (
          <span className={clsx("packagingInfo", className)}>
            {showProductUVP && !!productUVP && (
              <span
                className={clsx("packaging-info-uvp", hidePrices && "blurred")}
              >
                <span className="packaging-info-uvp-amount">
                  | UVP: <Price price={productUVP} />
                </span>
              </span>
            )}
            <span className="packagingInfoInner">
              {unitQuantity && (
                <span className="packagingInfo-amount">{unitQuantity}</span>
              )}
              {" x "}
              <span className="packagingInfoMeasurement">
                {productUnitMeasurement}
              </span>
            </span>
            {!isFruitOrVegetableProduct && basePriceFactor && hasBasePriceUnit && (
              <span
                className={clsx(
                  "packaging-info-base-unit",
                  hidePrices && "blurred"
                )}
              >
                <div className="packaging-info-base-unit-amount">
                  (
                  <Price price={productBasePrice} />
                  {" / "} {basePriceUnit})&nbsp;
                </div>
              </span>
            )}
          </span>
        )}
        {className !== "cart-package-info" && (
          <span className={clsx("packagingInfo", className)}>
            {!isFruitOrVegetableProduct && basePriceFactor && hasBasePriceUnit && (
              <>
                <span
                  className={clsx(
                    "packaging-info-base-unit",
                    hidePrices && "blurred"
                  )}
                >
                  <span className="packaging-info-base-unit-amount">
                    (
                    <Price price={productBasePrice} />
                    {" / "} {basePriceUnit})
                  </span>
                </span>
                <div className="pipe" />
              </>
            )}
            {showProductUVP && !!productUVP && (
              <>
                <span
                  className={clsx(
                    "packaging-info-uvp",
                    hidePrices && "blurred"
                  )}
                >
                  <span className="packaging-info-uvp-amount">
                    UVP: <Price price={productUVP} />
                    {tradingMargin > 0 && (
                      <Tooltip title="NETTO-Aufschlag">
                        &nbsp;{`(${tradingMargin}%)`}
                      </Tooltip>
                    )}
                  </span>
                </span>
                <div className="pipe" />
              </>
            )}
            <span className="packagingInfoInner">
              {unitQuantity && (
                <span className="packagingInfo-amount">{unitQuantity}</span>
              )}
              {" x "}
              <span className="packagingInfoMeasurement">
                {productUnitMeasurement}
              </span>
            </span>
          </span>
        )}
      </>
    );
  }
  return null;
};

export default ProductPackageInfo;
