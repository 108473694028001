import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ArticlePage from "./components/news/ArticlePage";
import ProductList from "./components/productList/ProductList";
import Cart from "./components/cart/Cart";
import OrderDetail from "./components/order/OrderDetail/OrderDetail";
import OrderConfirmation from "./components/OrderConfirmation/OrderConfirmation";
import ProductsViewWrapper from "./components/products/ProductsViewWrapper";
import ProductDetail from "./components/productDetail/ProductDetail";
import { pageTitleAppName, routePathNames } from "./appConfig";
import InfoCenter from "./components/infoCenter/InfoCenter";
import NewsOverview from "./components/news/NewsOverview";
import ProtectedPageLayoutWrapper from "./components/pages/ProtectedPageLayoutWrapper";
import UserProfileEdit from "./components/userProfile/UserProfileEdit";
import UserProfileAdd from "./components/userProfile/UserProfileAdd";
import UserProfileAdministration from "./components/userProfile/UserProfileAdministration";
import Categories from "./components/products/Categories";
import PriceTagList from "./components/priceTagList/PriceTagList";
import FavouriteList from "./components/FavouriteList/FavouriteList";
import OrderingInformation from "./components/pages/OrderingInformation";
import {
  Carts,
  Contact,
  Dashboard,
  Downloads,
  Impulse,
  Orders,
  QuickOrder,
  StandingOrders,
  Stocktaking,
  Stocktakings,
  Support,
  WebshopDashboard,
  Weekplanner,
} from "./components/pages";
import elementScrollToPosition from "./utils/elementScrollToPosition";
import { PortalTipModal, TermsModal } from "./components/molecules";

const App = function App() {
  const location = useLocation();

  useEffect(() => {
    elementScrollToPosition({});
  }, [location]);

  useEffect(() => {
    const onLocalStorageChange = (event: StorageEvent): void => {
      let newUserState: any = {};
      let oldUserState: any = {};

      if (event?.key !== "persist:primary") {
        return;
      }

      try {
        newUserState = JSON.parse(JSON.parse(event?.newValue)?.userData);
      } catch (error) {
        return;
      }

      if (!newUserState?.businessUnit) {
        // Reload other tabs on logout
        window.location.reload();
        return;
      }

      try {
        oldUserState = JSON.parse(JSON.parse(event?.oldValue)?.userData);
      } catch (error) {
        return;
      }

      if (
        newUserState?.businessUnit?.companyBusinessUnitKey ===
        oldUserState?.businessUnit?.companyBusinessUnitKey
      ) {
        return;
      }

      // Reload other tabs on company business unit key change
      window.location.reload();
    };

    window.addEventListener("storage", onLocalStorageChange);

    return () => {
      window.removeEventListener("storage", onLocalStorageChange);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitleAppName}</title>
      </Helmet>

      <ProtectedPageLayoutWrapper>
        <TermsModal />
        <PortalTipModal />
        <Routes>
          {/* Dashboard */}
          <Route path="/" element={<Dashboard />} />

          {/* Info Center */}
          <Route path={routePathNames.infoCenter} element={<InfoCenter />}>
            <Route path=":slug" element={<InfoCenter />} />
          </Route>

          {/* News */}
          <Route path={routePathNames.news} element={<NewsOverview />} />
          <Route path={routePathNames.article}>
            <Route path=":articleId" element={<ArticlePage />} />
          </Route>

          {/* Favorite Lists */}
          <Route
            path={routePathNames.favouriteList}
            element={<FavouriteList />}
          >
            <Route path=":listId" element={<FavouriteList />} />
          </Route>

          {/* Price Tags */}
          <Route
            path={routePathNames.priceTagList}
            element={<PriceTagList />}
          />

          {/* User Management */}
          <Route path={routePathNames.userProfileEdit}>
            <Route path=":userId" element={<UserProfileEdit />} />
          </Route>
          <Route
            path={routePathNames.userProfileAdd}
            element={<UserProfileAdd />}
          />
          <Route
            path={routePathNames.userProfilesAdministration}
            element={<UserProfileAdministration />}
          />

          {/* Shop */}
          <Route path={routePathNames.shop} element={<WebshopDashboard />} />
          <Route
            path={`${routePathNames.weekPlanner}${routePathNames.category}`}
          >
            <Route path=":categoryKey" element={<Weekplanner />} />
          </Route>
          <Route path={`${routePathNames.products}${routePathNames.category}`}>
            <Route
              path=":categoryKey"
              element={<ProductsViewWrapper WrappedComponent={ProductList} />}
            />
          </Route>
          <Route
            path={`${routePathNames.weekPlanner}${routePathNames.search}`}
            element={<Weekplanner />}
          />
          <Route
            path={`${routePathNames.products}${routePathNames.search}`}
            element={<ProductsViewWrapper WrappedComponent={ProductList} />}
          />
          <Route path={routePathNames.product}>
            <Route path=":sku" element={<ProductDetail />} />
          </Route>

          {/* Cart */}
          <Route path={routePathNames.cart} element={<Cart />} />
          <Route path={routePathNames.carts} element={<Carts />} />

          {/* Orders */}
          <Route path={routePathNames.orders} element={<Orders />} />
          <Route path={routePathNames.order}>
            <Route path=":id/:number" element={<OrderDetail />} />
          </Route>
          <Route
            path={routePathNames.orderConfirmation}
            element={<OrderConfirmation />}
          />

          {/* Quick Order */}
          <Route path={routePathNames.quickOrder} element={<QuickOrder />}>
            <Route path=":type" element={<QuickOrder />} />
          </Route>

          {/* Stocktakings */}
          <Route
            path={routePathNames.stocktakings}
            element={<Stocktakings />}
          />
          <Route path={routePathNames.stocktaking}>
            <Route path=":id" element={<Stocktaking />} />
          </Route>

          {/* Impulse */}
          <Route path={routePathNames.impulse} element={<Impulse />} />

          {/* Standing Orders */}
          <Route
            path={routePathNames.standingOrders}
            element={<StandingOrders />}
          />

          {/* Misc */}
          <Route
            path={routePathNames.orderingInformation}
            element={<OrderingInformation />}
          />
          <Route path={routePathNames.support} element={<Support />} />
          <Route path={routePathNames.downloads} element={<Downloads />} />

          <Route path={routePathNames.contact} element={<Contact />} />

          {/* If no route matches => redirect to non-protected route */}
          <Route path="*" element={<Navigate to={routePathNames.notFound} />} />
        </Routes>
        <Categories />
      </ProtectedPageLayoutWrapper>
    </>
  );
};

export default App;
