import React from "react";
import clsx from "clsx";
import { ReactComponent as EditIcon } from "../../../static/svg/edit.svg";

interface PrintViewCartNoteProps {
  cartNote: string;
  hasNotePersonalSpace: boolean;
}

const PrintViewCartNote: React.FC<PrintViewCartNoteProps> = (
  props: PrintViewCartNoteProps
) => {
  const { cartNote, hasNotePersonalSpace = false } = props;

  return (
    <div className="print-view-cart-note">
      <EditIcon className="print-view-cart-note__edit-icon" />
      <span
        className={clsx(
          hasNotePersonalSpace && "print-view-cart-note__personal-note"
        )}
      >
        IHRE PERSÖNLICHE NOTIZ
        <br />
        {cartNote}
      </span>
    </div>
  );
};

export default PrintViewCartNote;
