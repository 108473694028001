import { locationSearchQueryParameter } from "../appConfig";

const getURLSearchTerm = () => {
  let searchValue = "";
  const isSearchActive = window.location.search.includes(
    `${locationSearchQueryParameter.searchTerm}=`
  );
  if (isSearchActive) {
    const indexOfSearchQuery = window.location.search.indexOf(
      `${locationSearchQueryParameter.searchTerm}=`
    );

    searchValue = window.location.search.substring(
      indexOfSearchQuery +
        String(`${locationSearchQueryParameter.searchTerm}=`).length
    );

    if (searchValue.includes("&")) {
      const tempSearchValue = searchValue.split("&")[0];
      searchValue = tempSearchValue;
    }
  }

  return decodeURIComponent(searchValue);
};

export default getURLSearchTerm;
