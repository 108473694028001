import React from "react";

import { Link } from "react-router-dom";
import { Badge } from "antd";
import { useDispatch } from "react-redux";
import { locationSearchQueryParameter, routePathNames } from "../../appConfig";
import { ProductGroup } from "../../types/productGroup";
import HrDivider from "../divider/Divider";

interface Props {
  query: string;
  productGroup: Record<string, any>;
  context?: "productlist" | "weekplanner";
}

const ProductGroupSuggestion = (props: Props): any => {
  const { productGroup, context = "productlist", query } = props;

  const dispatch = useDispatch();

  const getItemTargetUrl = (name: string, filterUrlParam: string) => {
    const basePath =
      context === "weekplanner"
        ? routePathNames.weekPlanner
        : routePathNames.products;
    return `${basePath}${
      routePathNames.search
    }?${filterUrlParam}=${encodeURIComponent(name)}&${
      locationSearchQueryParameter.searchTerm
    }=${query}`;
  };

  return (
    <div className="productGroupWrapper">
      <div className="productGroup__title suggest-title">
        <span>Warengruppe</span>
      </div>
      <div className="productGroup__body suggest-body">
        {productGroup.slice(0, 5).map((productGroupItem: ProductGroup) => {
          return (
            <div
              className="suggest-item-container"
              key={`${productGroupItem.productGroupName}_${productGroupItem.amount}`}
            >
              <Link
                to={getItemTargetUrl(
                  productGroupItem.productGroupName,
                  "artikelwarengruppe"
                )}
                key={`${productGroupItem.productGroupName}_${productGroupItem.amount}`}
                onClick={() => {
                  dispatch({
                    type: "search/set-search-query",
                    payload: query || "",
                  });
                }}
              >
                <div className="productGroup__item suggest-item">
                  <span>{productGroupItem.productGroupName}</span>
                  <Badge
                    count={productGroupItem.amount}
                    // be sure that the ant class is the last one due to breaking the component behavior otherwise
                    className="badge-suggestion-count"
                    title={String(productGroupItem.amount)}
                  />
                </div>
              </Link>
            </div>
          );
        })}
        <HrDivider />
      </div>
    </div>
  );
};

export default ProductGroupSuggestion;
