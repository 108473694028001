import React from "react";

const PrintViewGreeting: React.FC = () => {
  return (
    <span className="print-view-greeting">
      Mit freundlichen Grüßen,
      <br />
      Ihr Team vom Weiling-Webshop
    </span>
  );
};

export default PrintViewGreeting;
