import React, { useRef, useState } from "react";
import clsx from "clsx";
import { message, Spin } from "antd";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { useSelector } from "react-redux";
import { ProductData } from "../../types/productData";
import { ReactComponent as BarCode } from "../../static/svg/barcode.svg";
import { messageData } from "../../appConfig";
import deleteProductsFromPriceTagList from "../../api/priceTagList/deleteProductsFromPriceTagList";
import postAddProductToPriceTagList from "../../api/priceTagList/postAddProductToPriceTagList";
import requestCatchHandler from "../../api/requestCatchHandler";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import useCancelAxiosOnUnmount from "../../hooks/useCancelAxiosOnUnmount";
import { UserListEntry } from "../../types/userListProducts";

interface Props {
  sku: ProductData["sku"];
  withText?: boolean;
}

/**
 * Button Component to add or remove a product from the label list
 * @param sku {string}
 * @param isActiveOnList {boolean}
 * @constructor
 */
const ProductAddToPriceTagListButton = function ProductAddToPriceTagList({
  sku,
  withText = false,
}: Props) {
  const isActiveOnList = useSelector(
    (state: any) =>
      state.priceTagList?.priceTags?.some(
        (priceTagListEntry: UserListEntry) => priceTagListEntry?.sku === sku
      ) || false
  );

  const buttonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(isActiveOnList);
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  // message content
  const {
    error: { unexpected: unexpectedMsg },
    success: {
      priceTagList: {
        addToList: addToListMsg,
        removeFromList: removeFromListMsg,
      },
    },
  } = messageData;

  /**
   * catch handler for on click update of price tag list
   * @param error {AxiosError}
   * @param state {boolean}
   */
  const rejectUpdatePriceTagListHandler = (
    error: AxiosError,
    state: boolean
  ) => {
    if (!axios.isCancel(error)) {
      // revert the state change again on failure
      setIsActive(state);
      setIsLoading(false);
      buttonRef?.current?.blur();
      message.error(unexpectedMsg);
    }

    requestCatchHandler(error);
  };

  /**
   * wrapper for on click resolve
   * handles state management
   */
  const resolveUpdatePriceTagListAction = () => {
    setIsActive(!isActive);
    setIsLoading(false);
    // remove focus to reveal effect
    buttonRef?.current?.blur();
  };

  /**
   * handle click based on state
   * alter state only after success of async call
   */
  const onClick = () => {
    setIsLoading(true);

    // axios payload
    const payload = {
      sku: [sku],
      cancelTokenSource: cancelTokenSource.current,
    };

    if (isActive) {
      deleteProductsFromPriceTagList(payload)
        .then(() => {
          resolveUpdatePriceTagListAction();
          message.success(removeFromListMsg);
        })
        .catch((error) => rejectUpdatePriceTagListHandler(error, true));
    } else {
      postAddProductToPriceTagList(payload)
        .then(() => {
          resolveUpdatePriceTagListAction();
          message.success(addToListMsg);
        })
        .catch((error) => rejectUpdatePriceTagListHandler(error, false));
    }
  };

  return (
    <button
      type="button"
      className={clsx(
        "productInfoIcon buttonWithSpin",
        { isActive },
        withText &&
          "button buttonText buttonWithIcon buttonTextDecoration--inverted"
      )}
      onClick={onClick}
      ref={buttonRef}
    >
      <Spin size="small" spinning={isLoading}>
        <BarCode className="icon iconTwoTone iconBarCode" />
        {withText && (
          <span className="color-primary">
            {isActive
              ? "Von Etikettenliste löschen"
              : "Auf Etikettenliste speichern"}
          </span>
        )}
      </Spin>
    </button>
  );
};

export default ProductAddToPriceTagListButton;
