import { useCallback, useEffect, useState } from "react";

import axios, { CancelTokenSource } from "axios";

import getCancelTokenSource from "../api/getCancelTokenSource";
import requestCatchHandler from "../api/requestCatchHandler";
import getStandingOrders from "../api/standingOrder/getStandingOrders";
import { itemsPerPage } from "../appConfig";
import {
  GetStandingOrdersAttributesResponse,
  StandingOrderData,
  StandingOrdersSorting,
} from "../types/standingOrder";

interface UseGetStStandingOrdersProps {
  deliveryDate: string;
  limit?: number;
  page?: number;
  sort?: StandingOrdersSorting;
}

const useGetStandingOrders = (props: UseGetStStandingOrdersProps) => {
  const {
    deliveryDate,
    limit = itemsPerPage.standingOrders,
    page = 1,
    sort,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [standingOrders, setStandingOrders] = useState<StandingOrderData[]>([]);
  const [total, setTotal] = useState<number>(0);

  const fetchStandingOrders = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      setIsLoading(true);

      const defaultParams = {
        deliveryDate,
        limit,
        page,
        sort,
        cancelTokenSource,
      };

      getStandingOrders({ ...defaultParams })
        .then(
          (
            standingOrdersAttributesResponse: GetStandingOrdersAttributesResponse
          ) => {
            setStandingOrders(
              standingOrdersAttributesResponse?.standingOrders || []
            );
            setTotal(standingOrdersAttributesResponse?.totalResults || 0);
            setIsLoading(false);
          }
        )
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setHasError(true);
            setIsLoading(false);
          }
          requestCatchHandler(error);
        });
    },
    [deliveryDate, limit, page, sort]
  );

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    fetchStandingOrders(cancelTokenSource).catch();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [fetchStandingOrders]);

  return {
    isLoading,
    hasError,
    total,
    standingOrders,
    setTotal,
    setStandingOrders,
  };
};

export default useGetStandingOrders;
