import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { message, Spin, Modal } from "antd";
import moment from "moment";
import axios, { CancelTokenSource } from "axios";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { productAttributes } from "../../../api/productAttributes";
import {
  ProductCount,
  ProductPackageInfo,
  ProductVolumePrices,
} from "../index";
import ProductPrices from "../ProductPrices";
import { ProductData, ProductPriceData } from "../../../types/productData";
import { fullDateFormat } from "../../../utils/dateFormats";
import getAllCartsDataByDate from "../../../api/cart/getAllCartsDataByDate";
import getProductDetail from "../../../api/products/getProductDetail";
import requestCatchHandler from "../../../api/requestCatchHandler";
import { messageData, routePathNames } from "../../../appConfig";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import deleteCartMessageAlternative from "../../../state/actions/deleteCartMessageAlternative";
import getCartErrorMessage from "../../../utils/getCartErrorMessage";
import getDeliveryDateBasedAttributes from "../getDeliveryDateBasedAttributes";
import getCancelTokenSource from "../../../api/getCancelTokenSource";
import useUpdateCartItemQuantity from "../../../hooks/useUpdateCartItemQuantity";
import getCartItemQuantity from "../../../utils/getCartItemQuantity";
import { RootState } from "../../../types/rootState";
import getAlreadyOrderedStorage from "../../../state/actions/getAlreadyOrderedStorage";
import ProductAlreadyOrderedBatch from "../ProductAlreadyOrderedBatch";
import ProductDetailModalContext from "../../../contexts/ProductDetailModalContext";
import ProductPreorderModalInner from "../ProductModal/ProductPreorderModalInner";

interface Props {
  deliveryDate: ProductData["deliveryDate"];
  productData: {
    attributes: ProductData["attributes"];
    sku: ProductData["sku"];
    availabilities: ProductData["availabilities"];
  };
  showVolumePrices?: boolean;
  preSelectedAmount?: number;
}

function ProductBasketPreorder({
  deliveryDate,
  productData,
  showVolumePrices = true,
}: Props) {
  const isInProductDetailModal = useContext(ProductDetailModalContext);
  const { pathname } = useLocation();
  const updateCartItemQuantity = useUpdateCartItemQuantity();

  const { attributes, sku, availabilities } = productData;
  const { nextAvailability, explanation } = getDeliveryDateBasedAttributes({
    deliveryDate,
    availabilities,
  });
  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.basePriceUnit]: basePriceUnit,
    [productAttributes.basePriceFactor]: basePriceFactor,
    [productAttributes.weighingArticle]: weighingArticle,
    [productAttributes.priceUnitText]: priceUnitText,
    [productAttributes.vatGroup]: vatGroup,
  } = attributes;

  const pastOrderedProduct = useSelector((state: RootState) => {
    return getAlreadyOrderedStorage(state, sku, deliveryDate);
  });

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  // cart & order data
  const [cartAndOrderData, setCartAndOrderData] = useState({
    id: null,
    quantity: 0,
    orderedQuantity: 0,
  });

  // price data, mimics structure of API data
  const [strikePrice, setStrikePrice] = useState(0);
  const [priceData, setPriceData] = useState({
    volumePrices: [],
    deliveryDate,
  });

  const [inCartErrorMsg, setInCartErrorMsg] = useState("");
  const [currentSelectedAmount, setCurrentSelectedAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [inputHasChanged, setInputHasChanged] = useState(false);

  const [isDatePickerModalOpen, setIsDatePickerModalOpen] =
    useState<boolean>(false);
  const [datePickerDate, setDatePickerDate] = useState<moment.Moment>(
    moment(nextAvailability)
  );
  const [wasAddedToCart, setWasAddedToCart] = useState<boolean>(false);
  const [productAddedToCart, setProductAddedToCart] = useState(false);

  let productBasketInputTimer: ReturnType<typeof setTimeout> = null;

  /**
   * Changes the slice of state when the input value changes
   * @param value {*}
   */
  const handleChange = (value: any) => {
    productBasketInputTimer = null;
    if (value !== 0 && value) {
      setCurrentSelectedAmount(value);
      setIsDatePickerModalOpen(true);
    } else {
      setCurrentSelectedAmount(0);
      setIsDatePickerModalOpen(false);
    }
  };

  const inputChanged = (value: any) => {
    if (productBasketInputTimer != null) {
      clearTimeout(productBasketInputTimer);
    }
    productBasketInputTimer = setTimeout(() => handleChange(value), 500);
  };

  /**
   * update cart on click event
   * @async
   */
  const addToCart = useCallback(
    (alternativeDeliveryDate = "") => {
      setInputHasChanged(true);

      // the deliveryDate is the next available date to preorder
      updateCartItemQuantity({
        deliveryDate: alternativeDeliveryDate || nextAvailability,
        cartId: cartAndOrderData.id,
        sku,
        quantity: currentSelectedAmount,
        cancelTokenSource: cancelTokenSource.current,
      })
        .then((res) => {
          const cartErrorMessage = getCartErrorMessage({
            response: res,
            sku,
          });

          if (cartErrorMessage) {
            setInCartErrorMsg(cartErrorMessage.reason);
            setIsDatePickerModalOpen(false);
            setCurrentSelectedAmount(0);
          }

          if (res?.data?.data?.attributes) {
            const { deliveryDateItems } = res.data.data.attributes;
            const quantity =
              deliveryDateItems.find((item: any) => item.sku === sku)
                ?.quantity || cartAndOrderData.quantity;

            // update components quantity to update badge
            setCartAndOrderData({
              ...cartAndOrderData,
              quantity,
            });
            setWasAddedToCart(true);
            setInputHasChanged(false);
            setProductAddedToCart(true);
            setCurrentSelectedAmount(0);
          }

          return cartErrorMessage;
        })
        .then((cartErrorMessage) => {
          /*
           * if the user is at the cart view
           * and the update of the item was successful
           * it should be save to assume, that the user interacted with the
           * "order alternatives" modal
           */
          if (pathname.includes(routePathNames.cart) && !cartErrorMessage) {
            return deleteCartMessageAlternative({
              deliveryDate,
              messageId: sku,
              sku,
              cancelTokenSource: cancelTokenSource.current,
            });
          }

          return Promise.resolve();
        })
        .catch((error) => {
          setInputHasChanged(false);

          if (!axios.isCancel(error)) {
            setInCartErrorMsg(
              error?.response?.data?.errors[0]?.detail ||
                messageData.error.unexpected.content
            );
          }
          requestCatchHandler(error);
        });
    },
    [
      deliveryDate,
      nextAvailability,
      pathname,
      cartAndOrderData,
      currentSelectedAmount,
      sku,
      updateCartItemQuantity,
    ]
  );

  /**
   * get all orders for the day and the current cart
   * aggregate information to display the correct volume prices and the cart badge
   */
  const getPreorderData = useCallback(async () => {
    setIsLoading(true);

    return Promise.all([
      getAllCartsDataByDate([nextAvailability], cancelTokenSource.current),
      getProductDetail({
        cancelTokenSource: cancelTokenSource.current,
        deliveryDate: nextAvailability,
        productSku: sku,
      }),
    ])
      .then(([cartResponse, { concreteProducts }]) => {
        if (cartResponse?.length) {
          const { id, deliveryDateItems } = cartResponse[0];

          const cartQuantity =
            deliveryDateItems?.length > 0
              ? getCartItemQuantity({
                  cartItems: deliveryDateItems,
                  lookupSku: sku,
                })
              : 0;

          setCartAndOrderData({
            id,
            quantity: cartQuantity,
            orderedQuantity: pastOrderedProduct || 0,
          });
        }

        /*
         * if a product is available for preorder
         * we need to display the prices for the correct date
         * which will be the "nextAvailability" date string
         */
        const preorderProductPrices = concreteProducts?.[0]?.prices;

        if (preorderProductPrices) {
          const { strikePrice: preOrderStrikePrice } =
            getDeliveryDateBasedAttributes({
              deliveryDate: nextAvailability,
              prices: preorderProductPrices,
            });

          // get the un-altered price set for the deliveryDate
          const deliveryDayPrices =
            preorderProductPrices.find(
              (priceSet: ProductPriceData) =>
                priceSet?.deliveryDate === nextAvailability
            ) || {};

          setStrikePrice(preOrderStrikePrice);
          setPriceData({
            ...deliveryDayPrices,
            deliveryDate: nextAvailability,
          });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);

        if (!axios.isCancel(error)) {
          message.error(messageData.error.unexpected);
        }

        requestCatchHandler(error);
      });
  }, [sku, nextAvailability, pastOrderedProduct]);

  useEffect(() => {
    getPreorderData();
  }, [getPreorderData]);

  useEffect(() => {
    if (inCartErrorMsg) {
      setWasAddedToCart(false);
    }
  }, [inCartErrorMsg]);

  // make sure the date is present
  if (!nextAvailability) {
    return null;
  }

  return isLoading ? (
    <div className="productBasket hasNextAvailability isLoading">
      <Spin size="default" className="nextAvailabilitySpin" />
    </div>
  ) : (
    <>
      {showVolumePrices && priceData?.volumePrices?.length > 0 && (
        <ProductVolumePrices
          volumePrices={priceData.volumePrices}
          basePriceQuantity={weighingArticle === "1" ? 1 : productUnitQuantity}
          availability={!!nextAvailability}
          totalOrderQuantity={
            Number(cartAndOrderData.quantity) + cartAndOrderData.orderedQuantity
          }
          className={clsx(strikePrice > 0 && "hasStrikePriceVolumePrices")}
        />
      )}

      <div className="productBasket hasNextAvailability">
        <span className="nextAvailabilityDateInfo">
          {explanation && (
            <span className="nextAvailabilityExplanation">
              <span className="nextAvailabilityExplanationText">
                {explanation}
              </span>
            </span>
          )}
          Lieferbar ab:{" "}
          <time className="nextAvailableDate">
            {moment(nextAvailability).format(fullDateFormat)}
          </time>
        </span>
        <div className="productPriceInfo">
          <ProductAlreadyOrderedBatch
            count={pastOrderedProduct}
            deliveryDate={deliveryDate}
          />
          <ProductPrices
            basePriceQuantity={productUnitQuantity}
            productUnitMeasurement={
              weighingArticle === "1" ? priceUnitText : productUnitMeasurement
            }
            className="productPriceInfo"
            deliveryDate={nextAvailability}
            prices={[priceData]}
            quantity={
              Number(cartAndOrderData.quantity) +
              cartAndOrderData.orderedQuantity
            }
            weighingArticle={weighingArticle}
            basePriceUnit={productUnitMeasurement}
            sku={sku}
          />
        </div>

        <ProductPackageInfo
          productUnitMeasurement={productUnitMeasurement}
          productUnitQuantity={productUnitQuantity}
          basePriceFactor={basePriceFactor}
          basePriceUnit={basePriceUnit}
          prices={[priceData]}
          deliveryDate={nextAvailability}
          quantity={
            Number(cartAndOrderData.quantity) + cartAndOrderData.orderedQuantity
          }
          weighingArticle={weighingArticle}
          showProductUVP
          sku={sku}
          vatGroup={vatGroup}
          basePriceQuantity={productUnitQuantity}
        />

        {!isInProductDetailModal && (
          <div className="addToCart addToCartPreorder">
            <div
              className={clsx(
                "remember-cart",
                cartAndOrderData.quantity > 0 && "remember-cart-green"
              )}
            >
              <ProductCount
                isLoading={inputHasChanged}
                value={currentSelectedAmount}
                handleChange={inputChanged}
                highlightThreshold={1}
                alertMessage={inCartErrorMsg}
                setAlertMessage={setInCartErrorMsg}
                containerClassName={clsx(
                  "productCountPreorder productListProductBasket",
                  inputHasChanged
                    ? "product-basket--loading"
                    : "product-basket--not-loading"
                )}
                onPressEnter={() => handleChange}
                useAddons
              />
              <Modal
                title="Liefertag wählen"
                visible={isDatePickerModalOpen}
                keyboard
                footer={null}
                wrapClassName="product-basket-preorder-modal"
                closable
                onCancel={() => {
                  setIsDatePickerModalOpen(false);
                  setWasAddedToCart(false);
                  if (!productAddedToCart) {
                    setCurrentSelectedAmount(0);
                  } else {
                    setProductAddedToCart(false);
                  }
                }}
                destroyOnClose
              >
                <ProductPreorderModalInner
                  wasAddedToCart={wasAddedToCart}
                  inCartErrorMsg={inCartErrorMsg}
                  sku={sku}
                  nextAvailability={nextAvailability}
                  datePickerDate={datePickerDate}
                  lastOrderDate={moment().add(1, "year")}
                  setDatePickerDate={setDatePickerDate}
                  setIsDatePickerModalOpen={setIsDatePickerModalOpen}
                  setWasAddedToCart={setWasAddedToCart}
                  setCurrentSelectedAmount={setCurrentSelectedAmount}
                  inputHasChanged={inputHasChanged}
                  addToCart={addToCart}
                />
              </Modal>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProductBasketPreorder;
