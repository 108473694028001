import React from "react";

import clsx from "clsx";
import { UserOutlined } from "@ant-design/icons";
import { Col, Layout, Row, Typography } from "antd";

import TrackingHelmet from "../../Matomo/TrackingHelmet";
import useGetContactPerson from "../../../hooks/useGetContactPerson";
import ButtonBackToTop from "../../buttons/ButtonBackToTop";
import HrDivider from "../../divider/Divider";
import Picture from "../../picture/Picture";
import { ContentBlock, QuickLink } from "../../atoms";
import {
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../../appConfig";
import leafImage from "../../../static/images/leaf_cherry.png";
import { ReactComponent as MailIcon } from "../../../static/svg/mail.svg";
import { ReactComponent as PhoneIcon } from "../../../static/svg/phone.svg";
import { ReactComponent as SupportIcon } from "../../../static/svg/support.svg";

const { Title } = Typography;

interface ContactProps {
  className?: string;
}

const Contact: React.FC<ContactProps> = (props: ContactProps) => {
  const { className } = props;

  const contactPerson = useGetContactPerson();

  return (
    <>
      <TrackingHelmet title={pageTitles.contact} suffix={pageTitleSuffix} />

      <div className={clsx("contact-page", className)}>
        <Layout className="container-layout container-layout--inner">
          <Row>
            <Col span={12}>
              <Title>Kontakt</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div className="contact-page__contact-person">
                {contactPerson?.picture && (
                  <Picture
                    imageScreenSm={contactPerson.picture}
                    alt="Foto von Kontaktperson"
                  />
                )}
                {!contactPerson?.picture && (
                  <UserOutlined className="icon user" />
                )}
                <div>
                  {contactPerson?.phone && (
                    <span>
                      <PhoneIcon className="icon phone" />
                      <a href={`tel:${contactPerson.phone}`}>
                        {contactPerson.phone}
                      </a>
                    </span>
                  )}
                  {contactPerson?.email && (
                    <span>
                      <MailIcon className="icon email" />
                      <a href={`mailto:${contactPerson.email}`}>
                        {contactPerson.email}
                      </a>
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <HrDivider spacingTop="m" spacingBottom="m" />
            <Col span={12} className="flex justify-center">
              <a
                className="contact-page__link"
                href="https://www.weiling.de/verkauf-beratung/regional-und-fachberatung"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="contact-page__link__link-text">
                  zum Regional- und
                  <br />
                  Fachaußendienst
                </span>
                <img src={leafImage} alt="Regional- und Fachaußendienst" />
              </a>
            </Col>{" "}
          </Row>
        </Layout>

        <div className="contact-page__support">
          <Layout className="container-layout">
            <ContentBlock
              title="Sie benötigen unmittelbar Hilfe?"
              icon={<SupportIcon />}
            >
              <p>
                Auf unserer Hilfe & Support Seite finden Sie Video-Tutorials und
                Antworten auf die am häufigsten gestellen Fragen zum
                Weiling-Portal auf einen Blick. Klicken Sie dazu bei Bedarf
                einfach auf den nachfolgenden Button.
              </p>
              <p>
                <QuickLink
                  label="Hilfe & Support"
                  href={routePathNames.support}
                  linkType="primary--outlined"
                />
              </p>
            </ContentBlock>
          </Layout>

          <ButtonBackToTop />
        </div>
      </div>
    </>
  );
};

export default Contact;
