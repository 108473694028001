import React, { useMemo, useState } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { FilterChip } from "../../atoms";
import {
  resetFilterStatesWithoutDefaults,
  updateFilterStates,
} from "../../products/ProductsFilter/filterFunctions";
import { RootState } from "../../../types/rootState";
import { FilterValue } from "../../../types/filters";

import { locationSearchQueryParameter } from "../../../appConfig";
import getURLSearchTerm from "../../../utils/getURLSearchTerm";

const hiddenFilterNames = [
  "deliveryDate",
  "sortBy",
  "sortDirection",
  "category",
];

interface ChipProps {
  text: string;
  label?: string;
  onRemove?: () => void;
}

interface SelectedFiltersProps {
  className?: string;
  numVisible?: number;
}

const SelectedFilters: React.FC<SelectedFiltersProps> = (props) => {
  const { className, numVisible = 5 } = props;

  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { availableFilters, filterStates } = useSelector(
    (state: RootState) => state.productsMetaData.filters
  );

  const { deliveryDate } = useSelector(
    (state: RootState) => state?.currentCartMetaData
  );

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const filterStateNames = useMemo(() => {
    return Object.keys(filterStates);
  }, [filterStates]);

  const displayedFilters = useMemo(
    () =>
      availableFilters
        .filter((filter) => {
          if (hiddenFilterNames.includes(filter.name)) {
            return false;
          }

          if (filterStateNames.includes(filter.name)) {
            return true;
          }

          return filter.activeValue && filter.activeValue !== null;
        })
        .sort((a, b) => (a.config.priority > b.config.priority ? -1 : 1)),
    [availableFilters, filterStateNames]
  );

  const searchValue = getURLSearchTerm();

  const allChips = useMemo<ChipProps[]>(() => {
    const chips: ChipProps[] = [];

    // Chip-filter for searchInput field
    if (searchValue?.length > 0) {
      chips.push({
        text: searchValue,
        onRemove: () => {
          dispatch({
            type: "search/delete-search-query",
          });

          navigate(
            `?${locationSearchQueryParameter?.deliveryDate}=${deliveryDate}&${locationSearchQueryParameter?.page}=1`
          );
        },
      });
    }

    displayedFilters.forEach((filter) => {
      if (!filter.activeValue) {
        chips.push({
          text: filter.localizedName,
          onRemove: () =>
            updateFilterStates(navigate, filter.name, false, search),
        });
      } else {
        filter.activeValue.forEach((activeValue) => {
          const value = filter.values.find(
            (filterValue: FilterValue) => filterValue?.value === activeValue
          );

          chips.push({
            text: value?.label || activeValue,
            label: filter.localizedName,
            onRemove: () =>
              updateFilterStates(
                navigate,
                filter.name,
                filter.activeValue.filter(
                  (singleFilter) => singleFilter !== activeValue
                ),
                search
              ),
          });
        });
      }
    });

    return chips;
  }, [displayedFilters, navigate, search, searchValue, deliveryDate, dispatch]);

  const displayedChips = useMemo(
    () =>
      allChips.filter((_chip, index) =>
        isExpanded ? true : index < numVisible
      ),
    [allChips, numVisible, isExpanded]
  );

  const showExpander = useMemo(
    () => allChips.length > numVisible,
    [allChips, numVisible]
  );

  if (displayedChips.length === 0) {
    return <></>;
  }

  if (
    displayedChips.find((chip) => chip.text === searchValue) &&
    displayedChips.length === 1
  ) {
    return (
      <div className={clsx("selected-filters", className)}>
        <span>Gesetzte Filter:</span>
        {displayedChips.map((chip, chipIndex) => (
          <FilterChip
            key={chipIndex}
            {...chip}
            onClose={() => chip.onRemove()}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={clsx("selected-filters", className)}>
      <span>Gesetzte Filter:</span>

      <FilterChip
        key="selected-filters_clear"
        variant="reset"
        text="Alle zurücksetzen"
        onClose={() => resetFilterStatesWithoutDefaults(navigate, search)}
      />

      {displayedChips.map((chip, chipIndex) => (
        <FilterChip key={chipIndex} {...chip} onClose={() => chip.onRemove()} />
      ))}

      {showExpander && (
        <FilterChip
          key="selected-filters_expand"
          text={
            isExpanded
              ? "Weniger anzeigen"
              : `+${allChips.length - displayedChips.length}`
          }
          onClose={() => setIsExpanded(!isExpanded)}
          closeIcon={isExpanded ? <UpOutlined /> : <DownOutlined />}
          className="filter-chip--secondary"
        />
      )}
    </div>
  );
};

export default SelectedFilters;
