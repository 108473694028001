import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { bnnDocumentEndpoint } from "../endpoints";

export type BnnExportType = 0 | 1 | 2;

interface GetBnnDocumentProps {
  exportType: BnnExportType;
  cancelTokenSource: CancelTokenSource;
}

const BNN_FILE_NAME_HEADER = "x-bnn-file-name";
const BNN_VERSION_3_0 = "3.0";
const BNN_VERSION_3_1 = "3.1";
const BASE_FILE_NAME = "PL";
const FILE_EXTENSION = ".bnn";

const getFallbackFileName = (exportType: BnnExportType): string => {
  switch (exportType) {
    case 1:
      return `${BASE_FILE_NAME}_OG${BNN_VERSION_3_0}${FILE_EXTENSION}`;
    case 2:
      return `${BASE_FILE_NAME}${BNN_VERSION_3_1}${FILE_EXTENSION}`;
    default:
      return `${BASE_FILE_NAME}${BNN_VERSION_3_0}${FILE_EXTENSION}`;
  }
};

const getBnnDocument = async (props: GetBnnDocumentProps) => {
  const { exportType = 0, cancelTokenSource } = props;

  return axiosWithAuth()
    .get(`${bnnDocumentEndpoint}?type=${exportType}`, {
      responseType: "blob",
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (response?.status !== 200) {
        Promise.reject(response);
      }

      const { headers = {} } = response;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute(
        "download",
        headers?.[BNN_FILE_NAME_HEADER] || getFallbackFileName(exportType)
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export default getBnnDocument;
