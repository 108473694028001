import {
  Button,
  Col,
  ConfigProvider,
  Layout,
  message,
  Row,
  Spin,
  Tooltip,
} from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShopCategoryNavigation from "../navigation/ShopCategoryNavigation";
import HrDivider from "../divider/Divider";
import EmptyListContent from "../EmptyListContent/EmptyListContent";
import Note from "../note/Note";
import ProductTileWithModal from "../product/ProductTile/ProductTileWithModal";
import { ProductData } from "../../types/productData";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";
import getProductsOnFavouritesList from "../../api/favouritesList/getProductsOnFavouritesList";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import {
  itemsPerPage,
  locationSearchQueryParameter,
  messageData,
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../appConfig";
import requestCatchHandler from "../../api/requestCatchHandler";
import patchFavouriteListNote from "../../api/favouritesList/patchFavouriteListNote";
import setPriceTagList from "../../state/actions/setPriceTagList";
import patchFavouriteListCaption from "../../api/favouritesList/patchFavouriteListCaption";
import EditableCaption from "../editableCaption/EditableCaption";
import FavouriteListsSelector from "./FavouriteListsSelector";
import { FavouriteList } from "../../types/favouriteList";
import HandleFavouriteListsModal from "./HandleFavouriteListsModal";
import setFavouriteLists from "../../state/actions/setFavouriteLists";
import { ReactComponent as Plus } from "../../static/svg/plus.svg";
import { ReactComponent as PrinterIcon } from "../../static/svg/printer.svg";
import QuickSelectProductInput from "./QuickSelectProductInput";
import postAddProductsToFavouritesLists from "../../api/favouritesList/postAddProductsToFavouritesLists";
import ReorderModal from "../order/OrderDetail/ReorderModal";
import BackButton from "../backButton/BackButton";
import PaginationWrapper from "../pagination/PaginationWrapper";
import useUpdateUrlFragments from "../../hooks/useUpdateUrlFragments";
import { FilterBar, SelectedFilters } from "../molecules";
import { getFilterStatesWithoutDefaults } from "../../api/products/getProductsFilters";
import { FiltersDesktop, PrintView } from "../organisms";
import FiltersMobile from "../products/ProductsFilter/FiltersMobile";
import getCssVariable from "../../utils/getCssVariable";
import useMedia from "../../hooks/useMedia";
import useGetSelectedFiltersCount from "../../hooks/useGetSelectedFiltersCount";
import ProductCountChangeDispatch from "../../contexts/ProductCountChangeDispatch";
import { RootState } from "../../types/rootState";
import useScrollToTop from "../../hooks/useScrollToTop";
import TrackingHelmet from "../Matomo/TrackingHelmet";
import { PrintViewFavouriteListTableDataProps } from "../../types/printViewFavouriteListTableData";

/**
 * favorite list
 * @constructor
 */
const FavouritesList = function FavoritesList() {
  const { listId } = useParams<{ listId?: string }>();
  const { search } = useLocation();
  const { locale } = useContext(ConfigProvider.ConfigContext);
  const [deliveryDate] = useGetDeliveryDate();
  const { selectedFiltersCount } = useGetSelectedFiltersCount();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setShouldScrollToTop } = useScrollToTop(window);

  const { activeList, favouriteLists } = useSelector(
    (state: RootState) => state.favouriteList
  );
  const { favouriteListDefaultSorting } = useSelector(
    (state: RootState) => state?.userData
  );

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [reorderProducts, setReorderProducts] = useState<ProductData[]>([]);
  const [noteIsUpdating, setNoteIsUpdating] = useState<boolean>(false);
  const [isCaptionUpdating, setIsCaptionUpdating] = useState<boolean>(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState(false);
  const [resultQuantity, setResultQuantity] = useState<number>(0);

  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-md")})`
  );

  const [paginationData, setPaginationData] = useState({
    current: 1,
    total: activeList?.favourites?.length || 0,
  });
  const setUpdateUrlFragments = useUpdateUrlFragments();
  const {
    [locationSearchQueryParameter.page]: pageQuery,
    [locationSearchQueryParameter.sortBy]: sortByQuery,
    [locationSearchQueryParameter.sortDirection]: sortDirectionQuery,
  } = queryString.parse(search);

  // message content
  const {
    error: {
      favouriteList: { addedProduct: addedProductMsg },
    },
    success: {
      favouriteList: { addedItemToList: addedItemToListsMsg },
    },
    warning: {
      favouriteList: { itemAlreadyAdded: alreadyAddedItemMsg },
    },
  } = messageData;

  const favouriteListsSorted = useMemo(() => {
    const collator = new Intl.Collator(locale?.locale || "de", {
      sensitivity: "base",
    });

    switch (favouriteListDefaultSorting) {
      case "name_asc":
        return favouriteLists.sort((a: FavouriteList, b: FavouriteList) =>
          collator.compare(a?.caption || "", b?.caption || "")
        );
      case "name_desc":
        return favouriteLists.sort((a: FavouriteList, b: FavouriteList) =>
          collator.compare(b?.caption || "", a?.caption || "")
        );
      case "updated_asc":
        return favouriteLists.sort(
          (a: FavouriteList, b: FavouriteList) =>
            (a?.lastUpdatedAt || 0) - (b?.lastUpdatedAt || 0)
        );
      case "updated_desc":
      default:
        return favouriteLists.sort(
          (a: FavouriteList, b: FavouriteList) =>
            (b?.lastUpdatedAt || 0) - (a?.lastUpdatedAt || 0)
        );
    }
  }, [favouriteLists, favouriteListDefaultSorting, locale]);

  /**
   * save notes on change
   * @param {string} notes
   * @returns {Promise<void>}
   */
  const onNoteChange = async (notes: string) => {
    setNoteIsUpdating(true);

    return patchFavouriteListNote({
      cancelTokenSource: cancelTokenSource.current,
      notes,
      listName: activeList.idFavouriteList,
    })
      .then(() => setFavouriteLists(cancelTokenSource.current))
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(messageData.error.unexpected);
        }

        requestCatchHandler(error);
      })
      .finally(() => setNoteIsUpdating(false));
  };

  /**
   * save caption on change
   * @param {string} caption
   * @returns {Promise<void>}
   */
  const onCaptionChange = async (caption: string) => {
    setIsCaptionUpdating(true);

    return patchFavouriteListCaption({
      cancelTokenSource: cancelTokenSource.current,
      caption,
      listName: activeList.idFavouriteList,
    })
      .then(() => setFavouriteLists(cancelTokenSource.current))
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(messageData.error.unexpected);
        }

        requestCatchHandler(error);
      })
      .finally(() => setIsCaptionUpdating(false));
  };

  /**
   * update pagination
   * @param {number} page
   */
  const paginationOnChange = (page: number) => {
    setUpdateUrlFragments({
      context: "pagination",
      parameters: {
        page,
      },
    });
  };

  /**
   * get product data for active favourite list
   * get also the current priceTagList to show correct states of icons
   * @param {string} favouriteListId
   * @param {number} page
   * @param {number} pageSize
   * @returns {Promise<void>}
   */
  const getProducts = useCallback(
    async ({
      favouriteListId,
      page,
      pageSize,
      sortBy,
      sortDirection,
    }: {
      favouriteListId: string;
      page: number;
      pageSize: number;
      sortBy: string;
      sortDirection: string;
    }) => {
      setIsLoading(true);

      return Promise.all([
        getProductsOnFavouritesList({
          cancelTokenSource: cancelTokenSource.current,
          deliveryDate,
          favouriteListId,
          requestFilters: getFilterStatesWithoutDefaults(),
          page,
          pageSize,
          sortBy,
          sortDirection,
        }),
        setPriceTagList(cancelTokenSource.current),
      ])
        .then(([{ concreteProducts, pagination }]) => {
          setProducts(concreteProducts);
          setReorderProducts(
            concreteProducts?.map((product: ProductData) => {
              return { ...product, quantity: 1 };
            }) || []
          );
          setResultQuantity(pagination?.numFound || 0);

          setPaginationData({
            current: pagination.currentPage,
            total: pagination.numFound,
          });

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);

          if (!axios.isCancel(error)) {
            message.error(messageData.error.unexpected);
          }

          requestCatchHandler(error);
        });
    },
    [deliveryDate]
  );

  /**
   * set the active favourite list accordingly to the provided id in the path or use the activated list from redux state
   * if no id or active list is provided the last edited list will be set as active
   * @returns {void}
   */
  const setActiveFavouriteList = useCallback(() => {
    if (favouriteLists?.length) {
      if (listId) {
        const favouriteListToBeActive = favouriteLists.find(
          (favouriteList: FavouriteList) =>
            favouriteList.idFavouriteList === listId
        );

        if (favouriteListToBeActive) {
          dispatch({
            type: "favouriteList/set-active-list",
            payload: {
              activeList: favouriteListToBeActive,
            },
          });
          return;
        }

        // if id from url cannot be found in the favourite lists the page and active list will be set to default state
        navigate(routePathNames.favouriteList, { replace: true });

        dispatch({
          type: "favouriteList/set-active-list",
          payload: {
            activeList: null,
          },
        });
        return;
      }
      if (activeList?.idFavouriteList) {
        navigate(
          `${routePathNames.favouriteList}${activeList?.idFavouriteList}`,
          { replace: true }
        );
        return;
      }

      // if no of the previous conditions is fulfilled the last edited list will be set as active (first list in favouriteLists)
      navigate(
        `${routePathNames.favouriteList}${favouriteLists[0].idFavouriteList}`,
        { replace: true }
      );
    }
  }, [listId, favouriteLists, activeList?.idFavouriteList, dispatch, navigate]);

  /**
   * check if item is already added to the active favourite list
   * @param {string} sku
   * @returns {boolean}
   */
  const isItemInFavouriteList = (sku: string): boolean => {
    return activeList?.favourites.some(
      (favourite: { sku: ProductData["sku"] }) => favourite.sku === sku
    );
  };

  /**
   * catch handler for on click update of price tag list
   * @param {AxiosError} error
   */
  const rejectUpdateFavouriteListHandler = (error: AxiosError) => {
    if (!axios.isCancel(error)) {
      setIsLoading(false);
      message.error(addedProductMsg);
      navigate(routePathNames.favouriteList, { replace: true });

      // refresh current favourite lists
      setFavouriteLists(cancelTokenSource.current);
    }

    requestCatchHandler(error);
  };

  /**
   * handler to add item to favourite list or display short hint that item is already added
   * @param {any} value
   */
  const addItemToFavouriteList = async (sku: string) => {
    if (!isItemInFavouriteList(sku)) {
      setIsLoading(true);

      // axios payload
      const addPayload = {
        cancelTokenSource: cancelTokenSource.current,
        listNames: [activeList?.idFavouriteList.toString()],
        skuList:
          sku
            ?.split(/[,|]/)
            ?.map((s: string) => s.trim())
            ?.filter(Boolean) || [],
      };

      await postAddProductsToFavouritesLists(addPayload)
        .then(() => {
          setFavouriteLists(cancelTokenSource.current).then(() => {
            setIsLoading(false);
            message.success(addedItemToListsMsg);
          });
        })
        .catch((error) => rejectUpdateFavouriteListHandler(error));
    } else {
      message.warning(alreadyAddedItemMsg);
    }
  };

  /**
   * toggle the reorder modal
   */
  const toggleReorderModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    if (listId) {
      getProducts({
        favouriteListId: listId,
        page: Number(pageQuery || 1),
        pageSize: itemsPerPage.products,
        sortBy: String(sortByQuery || ""),
        sortDirection: String(sortDirectionQuery || ""),
      }).then(() => setShouldScrollToTop(true));
    }
  }, [
    getProducts,
    listId,
    pageQuery,
    search,
    sortByQuery,
    sortDirectionQuery,
    setShouldScrollToTop,
  ]);

  useEffect(() => {
    setActiveFavouriteList();
  }, [setActiveFavouriteList]);

  useEffect(() => {
    setFavouriteLists(cancelTokenSource.current);
  }, []);

  const onProductCountChange = useCallback((sku: string, quantity: number) => {
    setReorderProducts(
      (prevState: ProductData[]) =>
        prevState?.map((product: ProductData) => {
          if (product?.sku !== sku) {
            return product;
          }

          return { ...product, quantity };
        }) || []
    );
  }, []);

  const printViewTableData: PrintViewFavouriteListTableDataProps[] = useMemo(
    () =>
      products.map((item) => {
        return {
          sku: item?.sku,
          description: item?.name,
          brand: item?.brand,
          itemBaseUnit: item?.attributes?.artikel_inhaltbasiseinheit,
          itemVpe: item?.attributes?.artikel_verpackungstext,
          unitPrice: item?.prices?.[0]?.volumePrices?.[0]?.defaultPrice,
          weighingArticle: item?.attributes?.artikel_wiegeartikel || "0",
          rrPrice: item?.prices?.[0]?.rrPrice || 0,
        };
      }),
    [products]
  );

  return (
    <>
      <TrackingHelmet
        title={pageTitles.favouriteList}
        suffix={pageTitleSuffix}
      />

      <div className="hidden-print">
        <ReorderModal
          productItems={reorderProducts}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalQuestion="Möchten Sie diese Artikel dem Warenkorb hinzufügen?"
        />
        <ShopCategoryNavigation />

        <Layout className="container-layout container-layout--inner">
          <BackButton />
          <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 4 }}
              lg={{ span: 6 }}
              xl={{ span: 7 }}
              xxl={{ span: 8 }}
            >
              <div className="favouritelist__top-bar">
                <EditableCaption
                  caption={activeList?.caption || "Favoritenliste"}
                  onSaveCaption={onCaptionChange}
                  isUpdating={isCaptionUpdating}
                />

                <Tooltip title="Favoritenliste drucken" placement="left">
                  <Button
                    onClick={() => window.print()}
                    className="button-print-favourite-list"
                    icon={<PrinterIcon className="icon" />}
                    type="text"
                  />
                </Tooltip>
              </div>
              <aside className="mb-m hidden-md-up">
                <Spin size="large" spinning={!activeList?.idFavouriteList}>
                  <button
                    type="button"
                    className="button buttonPrimary buttonPrimary--inverted width-full flex flex-row justify-center items-center text-sm"
                    onClick={() => setIsAddModalVisible(true)}
                  >
                    Favoritenlisten Übersicht
                  </button>
                  <Spin size="default" spinning={noteIsUpdating}>
                    <Note
                      onChange={onNoteChange}
                      title="Notiz"
                      maxLength={100}
                      showCount
                      text={activeList?.notes || ""}
                    />
                  </Spin>
                </Spin>
              </aside>
            </Col>
            <Col
              xs={{ span: 0 }}
              sm={{ span: 0 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xl={{ span: 5 }}
              xxl={{ span: 4 }}
            >
              <div className="productItemTableHeadInteractions">
                <Tooltip title="Favoritenliste drucken" placement="top">
                  <Button
                    onClick={() => window.print()}
                    className="button-print-favourite-list"
                    icon={<PrinterIcon className="icon" />}
                    type="text"
                  />
                </Tooltip>
                <button
                  type="button"
                  className="button buttonPrimary button__add-all-to-cart"
                  disabled={!products.length}
                  onClick={toggleReorderModal}
                >
                  Alles in den Warenkorb
                </button>

                <QuickSelectProductInput
                  isLoading={isLoading}
                  onSubmitValue={addItemToFavouriteList}
                  checkIfAlreadyOnList={isItemInFavouriteList}
                  isAlreadyOnListWarning="ist bereits auf dieser Liste"
                  type="favoriteList"
                />
              </div>
            </Col>
          </Row>

          <HrDivider size={2} spacingTop="m" spacingBottom="xl" />

          <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 0 }}>
              <button
                type="button"
                className="button buttonPrimary width-full"
                disabled={!products.length}
                onClick={toggleReorderModal}
              >
                Alles in den Warenkorb
              </button>
              <QuickSelectProductInput
                isLoading={isLoading}
                onSubmitValue={addItemToFavouriteList}
                checkIfAlreadyOnList={isItemInFavouriteList}
                isAlreadyOnListWarning="ist bereits auf dieser Liste"
                type="favoriteList"
              />
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
            <Col xs={12} md={4} xl={3} className="hidden-sm-down">
              <Spin size="large" spinning={!activeList?.idFavouriteList}>
                <aside className="mb-m">
                  <Spin size="default" spinning={noteIsUpdating}>
                    <Note
                      onChange={onNoteChange}
                      title="Notiz"
                      maxLength={100}
                      showCount
                      text={activeList?.notes || ""}
                    />
                  </Spin>
                  <FavouriteListsSelector
                    favouriteLists={favouriteListsSorted}
                  />
                  <button
                    type="button"
                    className="button buttonPrimary buttonPrimary--inverted width-full flex flex-row justify-center items-center text-sm"
                    onClick={() => setIsAddModalVisible(true)}
                  >
                    <Plus className="icon" />
                    &nbsp;Neue Liste
                  </button>
                </aside>
              </Spin>
            </Col>
            <Col xs={12} md={8} xl={9}>
              <FilterBar
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                showSearchFilter
                selectedFiltersCount={selectedFiltersCount}
              />
              {showFilters &&
                (browserIsDesktop ? (
                  <FiltersDesktop
                    showFilters={showFilters}
                    hideFilters={() => setShowFilters(false)}
                    loading={isLoading}
                    display="weekplanner"
                  />
                ) : (
                  <FiltersMobile
                    quantity={resultQuantity}
                    loading={isLoading}
                    closeMobileFilters={() => setShowFilters(false)}
                    className="hidden-md-up"
                  />
                ))}

              <SelectedFilters />

              <Spin size="large" spinning={isLoading}>
                {products?.length ? (
                  <>
                    <ProductCountChangeDispatch.Provider
                      value={onProductCountChange}
                    >
                      {products.map((productEntry: ProductData) => (
                        <ProductTileWithModal
                          deliveryDate={deliveryDate}
                          productData={productEntry}
                          key={productEntry.sku}
                        />
                      ))}
                    </ProductCountChangeDispatch.Provider>
                  </>
                ) : (
                  <EmptyListContent
                    description="Noch keine Produkte in der Favoritenliste."
                    withRedirectButton
                  />
                )}
              </Spin>
            </Col>
          </Row>
        </Layout>

        <PaginationWrapper
          current={paginationData.current}
          pageSize={itemsPerPage.products}
          onChange={paginationOnChange}
          total={paginationData.total}
        />

        <HandleFavouriteListsModal
          favouriteLists={favouriteListsSorted}
          isVisible={isAddModalVisible}
          hideModal={() => setIsAddModalVisible(false)}
          modalHeading="Neue Favoritenliste erstellen"
        />
      </div>

      <PrintView
        cartNote={activeList?.notes || ""}
        favouriteListName={activeList?.caption || ""}
        printViewFavouriteListTableData={printViewTableData}
        printLayout="favouriteList"
      />
    </>
  );
};

export default FavouritesList;
