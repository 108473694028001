import React, { useMemo } from "react";

import clsx from "clsx";
import { ConfigProvider, Empty, Table } from "antd";

import { itemsPerPage } from "../../../../appConfig";
import { columns } from "./columns";
import { StandingOrderData } from "../../../../types/standingOrder";

interface StandingOrderTableProps {
  standingOrders: StandingOrderData[];
  total: number;
  page: number;
  isLoading: boolean;
  className?: string;
  emptyDescription?: JSX.Element;
  onTableChange?: (pagination: any, filters: any, sorter: any) => void;
}

const StandingOrderTable: React.FC<StandingOrderTableProps> = (
  props: StandingOrderTableProps
) => {
  const {
    standingOrders,
    total,
    page,
    isLoading,
    className,
    emptyDescription,
    onTableChange,
  } = props;

  const tableDataSource = useMemo(
    () =>
      standingOrders.map((standingOrderData: StandingOrderData) => ({
        ...standingOrderData,
        key: standingOrderData.idStandingOrder,
      })),
    [standingOrders]
  );

  const renderEmpty = () => (
    <Empty
      className="mb-3xl"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={emptyDescription}
    />
  );

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <Table
        className={clsx("productItemTable", "standing-orders-table", className)}
        sticky
        showSorterTooltip={false}
        columns={columns}
        dataSource={tableDataSource}
        scroll={{ x: "max-content" }}
        tableLayout="fixed"
        loading={isLoading && { size: "large" }}
        pagination={{
          position: ["bottomCenter"],
          total,
          pageSize: itemsPerPage.standingOrders,
          current: page,
          showSizeChanger: false,
          showLessItems: true,
        }}
        onChange={onTableChange}
      />
    </ConfigProvider>
  );
};

export default StandingOrderTable;
