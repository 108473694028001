import {
  PriceTagListEntry,
  PriceTagListPrintType,
} from "../../types/priceTagList";

const initialState = {
  priceDate: <null | string>null,
  priceTags: <PriceTagListEntry[]>[],
  printTypes: <PriceTagListPrintType[]>[],
  selectedPriceTagItems: <string[]>[],
};

const priceTagListReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // set price date
    case "priceTagList/set-priceDate": {
      return {
        ...state,
        priceDate: payload?.priceDate || null,
      };
    }

    // set all available print types retrieved from backend
    case "priceTagList/set-printTypes": {
      return {
        ...state,
        printTypes: payload?.printTypes || [],
      };
    }

    // set response from server to redux
    case "priceTagList/set-list": {
      const priceTags = payload?.priceTags || [];
      const currentPriceTags = state?.priceTags || [];

      const shouldOverwritePriceTags =
        priceTags?.findIndex(
          (priceTag: any) => priceTag?.price === undefined
        ) === -1;

      if (!shouldOverwritePriceTags) {
        const currentPriceTagsSkus = currentPriceTags.map(
          (currentPriceTag: PriceTagListEntry) => currentPriceTag.sku
        );

        const newPriceTags = priceTags
          ?.filter(
            (priceTag: any) => !currentPriceTagsSkus.includes(priceTag.sku)
          )
          ?.map((priceTag: any): { sku: any; price: any } => {
            return { sku: priceTag.sku, price: null };
          });

        return {
          ...state,
          priceTags: [...currentPriceTags, ...newPriceTags],
        };
      }

      return {
        ...state,
        priceTags: [...priceTags],
      };
    }

    // overwrite list with prefilled data without check if data is valid
    case "priceTagList/set-prefilled-list": {
      const priceTags = payload?.priceTags || [];
      const currentPriceTags = state?.priceTags || [];

      const priceTagsSkus =
        priceTags?.map((priceTag: PriceTagListEntry) => priceTag.sku) || [];

      const newPriceTags = currentPriceTags.map(
        (currentPriceTag: PriceTagListEntry) => {
          if (priceTagsSkus.includes(currentPriceTag.sku)) {
            const foundPriceTag = priceTags?.find(
              (priceTag: any) => priceTag?.sku === currentPriceTag?.sku
            );

            if (foundPriceTag) {
              return {
                ...currentPriceTag,
                price: foundPriceTag?.price || null,
              };
            }
          }

          return currentPriceTag;
        }
      );

      return {
        ...state,
        priceTags: [...newPriceTags],
      };
    }

    // remove a single sku from the list
    case "priceTagList/remove-sku": {
      const restSkuList = state.priceTags?.filter((entry) =>
        payload?.some((removeSku: string) => entry.sku !== removeSku)
      );

      return {
        ...state,
        priceTags: restSkuList || [],
      };
    }

    // removes selected skus from the list
    case "priceTagList/remove-selected-skus": {
      const newSkuList = state?.priceTags?.filter(
        (entry) => !payload?.includes(entry.sku)
      );

      return {
        ...state,
        priceTags: newSkuList || [],
        selectedPriceTagItems: [],
      };
    }

    case "priceTagList/add-price-to-sku": {
      // filter out the entry to alter
      const restSkuList = state.priceTags?.filter(
        (entry) => entry.sku !== payload?.sku
      );

      // add a new set
      const newPriceTagList = [...restSkuList, { ...payload }];

      return {
        ...state,
        priceTags: newPriceTagList,
      };
    }

    // add a sku to the selectedPriceTagItems array
    case "priceTagList/add-selected-priceTag-item": {
      const selectedPriceTagItems = [...state.selectedPriceTagItems];

      if (selectedPriceTagItems.indexOf(payload) < 0) {
        selectedPriceTagItems.push(payload);
      }

      return {
        ...state,
        selectedPriceTagItems,
      };
    }

    // delete a sku from the selectedPriceTagItems array
    case "priceTagList/delete-selected-priceTag-item": {
      const remainingItemSkus = state.selectedPriceTagItems?.filter(
        (currentSku: string) => currentSku !== payload
      );

      return {
        ...state,
        selectedPriceTagItems: remainingItemSkus,
      };
    }

    // Reset data
    case "priceTagList/reset-price-tag-list": {
      return {
        ...state,
        selectedPriceTagItems: [],
        priceTags: [],
      };
    }

    // Default case, just returns the initialState/currentState
    default:
      return state;
  }
};

export default priceTagListReducer;
