/**
 * Check if a product is a fruit or vegetable
 * @param sku
 */
const isProductFruitOrVegetable = (sku = "") =>
  sku &&
  sku?.length === 6 &&
  ["12", "21", "22", "23"].includes(sku.slice(0, 2));

export default isProductFruitOrVegetable;
