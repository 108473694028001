import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";

import { updateProductOnPriceTagListEndpoint } from "../endpoints";
import { PriceTagListEntry } from "../../types/priceTagList";

interface UpdateProductsOnPriceTagListProps {
  priceTags: PriceTagListEntry[];
  cancelTokenSource: CancelTokenSource;
}

const updateProductsOnPriceTag = async (
  props: UpdateProductsOnPriceTagListProps
): Promise<string[]> => {
  const { priceTags, cancelTokenSource } = props;

  const url = `${updateProductOnPriceTagListEndpoint}/multi`;

  const payload = {
    data: {
      type: "tags",
      attributes: {
        priceTags,
      },
    },
  };

  return axiosWithAuth()
    .patch(url, payload, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (
        !response ||
        response.status !== 200 ||
        !response?.data?.data?.attributes?.isSuccess
      ) {
        return Promise.reject(response);
      }

      const { skuList } = response?.data?.data?.attributes || {};

      return (skuList || []) as string[];
    });
};

export default updateProductsOnPriceTag;
