import React from "react";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { locationSearchQueryParameter, routePathNames } from "../../appConfig";

interface Props {
  completion: Record<string, any>;
  context?: "productlist" | "weekplanner";
  updateComponentStates?: (query: string) => void;
}

const CompletionSuggestion = (props: Props): any => {
  const { completion, context = "productlist", updateComponentStates } = props;

  const dispatch = useDispatch();

  const getItemTargetUrl = (name: string) => {
    const basePath =
      context === "weekplanner"
        ? routePathNames.weekPlanner
        : routePathNames.products;
    return `${basePath}${routePathNames.search}?${locationSearchQueryParameter.searchTerm}=${name}`;
  };
  return (
    <div className="completionWrapper">
      <div className="completion__title suggest-title">
        <span>Suchbegriff</span>
      </div>
      <div className="completion__body suggest-body">
        {completion.slice(0, 5).map((completionItem: any) => {
          return (
            <div key={`${completionItem}`} className="suggest-item-container">
              <Link
                to={getItemTargetUrl(completionItem)}
                key={`${completionItem}`}
                onClick={() => {
                  dispatch({
                    type: "search/set-search-query",
                    payload: completionItem,
                  });
                  updateComponentStates(completionItem);
                }}
              >
                <div className="productGroup__item suggest-item">
                  <span>{completionItem}</span>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompletionSuggestion;
