import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { orderOverviewDocumentEndpoint } from "../endpoints";

interface GetOrderOverviewDocumentProps {
  startDate: string;
  endDate: string;
  codes?: string[];
  cancelTokenSource: CancelTokenSource;
}

const getOrderOverviewDocument = async (
  props: GetOrderOverviewDocumentProps
) => {
  const { startDate, endDate, codes = [], cancelTokenSource } = props;

  let url = `${orderOverviewDocumentEndpoint}?startDate=${startDate}&endDate=${endDate}`;

  if (Array.isArray(codes) && codes.length) {
    url += `&code[]=${codes.join("&code[]=")}`;
  }

  return axiosWithAuth()
    .get(url, {
      responseType: "blob",
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (response?.status !== 200) {
        Promise.reject(response);
      }

      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = objectUrl;
      link.setAttribute("download", "Vormerkungen.pdf");
      document.body.appendChild(link);
      link.click();
    });
};

export default getOrderOverviewDocument;
