import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Layout, message, Row, Spin } from "antd";
import UserProfileForm from "./form/UserProfileForm";
import { CompanyUserProfile } from "../../types/companyUserProfile";
import patchCompanyUser from "../../api/userProfile/patchCompanyUser";
import getSingleCompanyUserData from "../../api/userProfile/getSingleCompanyUserData";
import { pageTitles, pageTitleSuffix, routePathNames } from "../../appConfig";
import NotAllowed from "./NotAllowed";
import storeDispatch from "../../state/actions/storeDispatch";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import { BusinessUnitPatchForm } from "../organisms";
import HrDivider from "../divider/Divider";
import TrackingHelmet from "../Matomo/TrackingHelmet";

/**
 * user profile edit component with encapsulated logics
 */
function UserProfileEdit() {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isNotAllowed, setNotAllowed] = useState(false);
  const [user, setUser] = useState({});
  const { isSuperAdmin, id: superAdminId } = useSelector(
    (state: any) => state.userData
  );

  const setUserData = (userData: CompanyUserProfile) => {
    setIsLoading(true);

    patchCompanyUser({ ...userData, id: userId || superAdminId })
      .then((companyUserData) => {
        setUser(companyUserData);

        // update stored preferences
        storeDispatch(
          "news/set-user-preferences",
          companyUserData?.newsPreferences
        );

        storeDispatch("user/update-user", {
          favouriteListDefaultSorting:
            companyUserData?.favouriteListDefaultSorting,
          deliveryDates: companyUserData?.deliveryDates || [],
          showPortalTips: companyUserData?.showPortalTips,
        });

        setIsLoading(false);
        if (isSuperAdmin) {
          navigate(routePathNames.userProfilesAdministration);
        }
        message.success({
          content: "Die Benutzerdaten wurden erfolgreich geändert",
          duration: 3,
        });
      })
      .catch(() => {
        message.error({
          content:
            "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
          duration: 3,
        });
      });
  };

  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();

    setIsLoading(true);
    if (isSuperAdmin || userId === superAdminId) {
      getSingleCompanyUserData(userId, cancelTokenSource)
        .then((companyUserData: any) => {
          if (companyUserData) {
            setUser(companyUserData);
          }
          setIsLoading(false);
        })
        .catch(() => {
          message.error({
            content:
              "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
            duration: 3,
          });
        });
    } else {
      setNotAllowed(true);
      setIsLoading(false);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [isSuperAdmin, userId, superAdminId, setIsLoading]);

  return (
    <>
      <TrackingHelmet
        title={
          userId !== superAdminId
            ? pageTitles.userProfilesAdministration
            : pageTitles.userAccount
        }
        suffix={pageTitleSuffix}
      />

      <Layout
        className="container-layout container-layout--inner"
        key={`${routePathNames.userProfileEdit}${userId}`}
      >
        {isLoading && (
          <Row>
            <Col span={12}>
              <Spin size="large" style={{ display: "block" }} />
            </Col>
          </Row>
        )}
        {Object.keys(user)?.length > 0 && !isLoading && (
          <UserProfileForm userData={user} setUserData={setUserData} />
        )}

        {isNotAllowed === true && !isLoading && <NotAllowed />}

        {isSuperAdmin && userId === superAdminId && (
          <>
            <h2>Filial-Einstellungen</h2>
            <HrDivider spacingBottom="sm" className="hidden-lg-up" />
            <BusinessUnitPatchForm className="mb-l" />
          </>
        )}
      </Layout>
    </>
  );
}
export default UserProfileEdit;
