import React, { forwardRef } from "react";

import clsx from "clsx";
import { Collapse, Empty, Result, Spin } from "antd";
import { Entry } from "contentful";

import useGetPortalTip from "../../../../hooks/useGetPortalTip";
import renderContentfulDocument from "../../../../utils/renderContentfulDocument";
import { ContentBlock } from "../../../atoms";
import { ReactComponent as Arrow } from "../../../../static/svg/arrow.svg";
import { ReactComponent as BulbIcon } from "../../../../static/svg/bulb.svg";
import { ContentfulPortalTipEntry } from "../../../../types/contentfulPortalTipEntry";

const { Panel } = Collapse;

interface SupportPortalTipsProps {
  className?: string;
}

type Ref = HTMLDivElement;

const SupportPortalTips: React.FC<SupportPortalTipsProps> = forwardRef<
  Ref,
  SupportPortalTipsProps
>((props, ref) => {
  const { className } = props;

  const { isLoading, hasError, entries } = useGetPortalTip({
    limit: 25,
    shouldFilterByDates: false,
  });

  return (
    <div ref={ref} className={clsx("support-portal-tips", className)}>
      <Spin spinning={isLoading} tip="Lade Tipps & Tricks...">
        <ContentBlock
          title="Tipps & Tricks"
          className="support-page__portal-tips"
        >
          {!isLoading &&
            !hasError &&
            Array.isArray(entries) &&
            !!entries?.length && (
              <Collapse
                accordion
                expandIcon={({ isActive }) => (
                  <Arrow
                    style={{
                      transform: isActive ? "rotate(180deg)" : "rotate( 0deg)",
                    }}
                  />
                )}
                expandIconPosition="end"
                destroyInactivePanel
                ghost
              >
                {entries?.map((entry: Entry<ContentfulPortalTipEntry>) => (
                  <Panel
                    header={entry?.fields?.headline || ""}
                    key={`support-tip_${entry?.sys?.id}`}
                    extra={<BulbIcon />}
                  >
                    {renderContentfulDocument(entry?.fields?.content || null)}
                  </Panel>
                ))}
              </Collapse>
            )}

          {!isLoading &&
            !hasError &&
            (!Array.isArray(entries) || !entries?.length) && (
              <Empty
                description="Aktuell sind keine Tipps & Tricks verfügbar"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}

          {!isLoading && !!hasError && (
            <Result
              status="error"
              title="Fehler beim Laden der Tipps & Tricks"
            />
          )}
        </ContentBlock>
      </Spin>
    </div>
  );
});

export default SupportPortalTips;
