import React from "react";

import { Link } from "react-router-dom";

import Price from "../../../price/Price";
import { getWeekdayShipmentDateFullName } from "./utils";
import { routePathNames } from "../../../../appConfig";
import { productAttributes } from "../../../../api/productAttributes";

export const columns: any = [
  {
    title: "Art.-Nr.",
    width: 60,
    dataIndex: "sku",
    sorter: true,
  },
  {
    title: "Artikelbezeichnung",
    width: 220,
    dataIndex: "sku",
    render: (sku: string, record: any) => {
      const { name = "-" } = record?.product || {};

      if (!sku) {
        return <>{name}</>;
      }

      return (
        <Link to={`${routePathNames.product}${sku}`} className="product-link">
          {name}
        </Link>
      );
    },
  },
  {
    title: "Anzahl",
    width: 70,
    dataIndex: "quantity",
    sorter: true,
  },
  {
    title: "VPE",
    width: 175,
    dataIndex: "vpe",

    render: (_vpe: string, record: any) => {
      const { product = {} } = record;
      const { attributes = {} } = product || {};

      const {
        [productAttributes.package]: vpeText,
        [productAttributes.vpeQuantity]: vpeQuantity,
      } = attributes;

      if (!vpeText && !vpeQuantity) {
        return "-";
      }
      return (
        <span className="text-sm text-bold">
          {`${vpeQuantity || ""}${vpeText ? ` x ${vpeText}` : ""}`}
        </span>
      );
    },
  },
  {
    title: "Wochentag Lieferung",
    width: 150,
    dataIndex: "weekdayShipmentDate",
    render: (weekdayShipmentDate: string) =>
      getWeekdayShipmentDateFullName(weekdayShipmentDate),
  },
  {
    title: "Intervall",
    width: 150,
    dataIndex: "interval",
    render: (interval: string) => interval || "-",
  },
  {
    title: "Einzelpreis",
    width: 120,
    dataIndex: "sku",
    render: (_sku: string, record: any) =>
      record?.price?.tiers?.[0]?.price ? (
        <Price
          tag="span"
          className="text-sm text-bold"
          price={record?.price?.tiers?.[0]?.price}
        />
      ) : (
        "-"
      ),
  },
  {
    title: "Gesamtsumme",
    width: 120,
    dataIndex: "quantity",
    fixed: "right",
    render: (quantity: number, record: any) =>
      record?.price?.tiers?.[0]?.price && quantity ? (
        <Price
          tag="span"
          className="text-sm text-bold"
          price={
            Number(record?.price?.tiers?.[0]?.price || 0) * Number(quantity)
          }
        />
      ) : (
        "-"
      ),
  },
];
