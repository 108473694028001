import { PortalTipState } from "../../types/portalTip";

const initialState: PortalTipState = {
  hiddenTipIds: [],
};

const portalTipReducer = (
  state: PortalTipState = initialState,
  action: any
) => {
  const { type, payload } = action || {};
  const { tipId } = payload || {};
  const { hiddenTipIds = [] } = state || {};

  switch (type) {
    case "portal-tip/hide-tip-by-id": {
      if (!tipId || hiddenTipIds.includes(tipId)) {
        return state;
      }

      return { ...state, hiddenTipIds: [...hiddenTipIds, tipId] };
    }

    case "portal-tip/un-hide-tip-by-id": {
      if (!tipId || !hiddenTipIds.includes(tipId)) {
        return state;
      }

      return {
        ...state,
        hiddenTipIds: [
          ...hiddenTipIds.filter(
            (hiddenTipId: string) => hiddenTipId !== tipId
          ),
        ],
      };
    }

    case "portal-tip/reset": {
      return initialState;
    }

    default:
      return state;
  }
};

export default portalTipReducer;
