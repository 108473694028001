import React, { useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import moment, { Moment } from "moment";
import { useForm } from "antd/lib/form/Form";
import axios, { CancelTokenSource } from "axios";
import {
  Button,
  DatePicker,
  Form,
  message,
  Modal,
  Select,
  SelectProps,
} from "antd";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import getOrderOverviewCategoryList from "../../../api/order/getOrderOverviewCategoryList";
import getOrderOverviewDocument from "../../../api/order/getOrderOverviewDocument";
import requestCatchHandler from "../../../api/requestCatchHandler";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import { messageData } from "../../../appConfig";
import { apiDateFormat, fullDateFormat } from "../../../utils/dateFormats";
import { OrderOverviewCategory } from "../../../types/orderOverview";
import { ReactComponent as DownloadIcon } from "../../../static/svg/download.svg";

interface OrderOverviewModalProps {
  className?: string;
  wrapperClassName?: string;
}

const { RangePicker } = DatePicker;

const OrderOverviewModal: React.FC<OrderOverviewModalProps> = (
  props: OrderOverviewModalProps
) => {
  const { className, wrapperClassName } = props;

  const [form] = useForm();

  const hasMountedRef = useRef<boolean>(false);
  const categoriesCancelTokenSource = useRef<CancelTokenSource>(
    getCancelTokenSource()
  );
  useCancelAxiosOnUnmount(categoriesCancelTokenSource.current);

  const downloadCancelTokenSource = useRef<CancelTokenSource>(
    getCancelTokenSource()
  );
  useCancelAxiosOnUnmount(downloadCancelTokenSource.current);

  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment().add(14, "days"));
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const downloadOrderOverviewDocument = () => {
    downloadCancelTokenSource.current = cancelAndRenewCancelToken(
      downloadCancelTokenSource.current
    );
    setIsDownloading(true);
    getOrderOverviewDocument({
      startDate: startDate.format(apiDateFormat),
      endDate: endDate.format(apiDateFormat),
      codes: selectedCodes,
      cancelTokenSource: downloadCancelTokenSource.current,
    })
      .then(() => {
        message.success(
          messageData.success.orders.overviewDocumentDownloadSuccess
        );
        setIsDownloading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(messageData.error.orders.overviewDocumentDownloadError);
          setIsDownloading(false);
        }
        requestCatchHandler(error);
      });
  };

  const getCategories = useCallback(() => {
    categoriesCancelTokenSource.current = cancelAndRenewCancelToken(
      categoriesCancelTokenSource.current
    );

    getOrderOverviewCategoryList({
      cancelTokenSource: categoriesCancelTokenSource.current,
    })
      .then((orderOverviewCategories: OrderOverviewCategory[]) => {
        setOptions(
          orderOverviewCategories?.map(
            (orderOverviewCategory: OrderOverviewCategory) => {
              return {
                label: orderOverviewCategory.description,
                value: orderOverviewCategory.code,
              };
            }
          )
        );
      })
      .catch((error) => {
        requestCatchHandler(error);
      });
  }, []);

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true;
      getCategories();
    }

    return () => {
      hasMountedRef.current = false;
    };
  }, [getCategories]);

  useEffect(() => {
    if (isModalVisible) {
      form.validateFields().catch(() => {});
    }
  }, [isModalVisible, form]);

  return (
    <div className={clsx("order-overview-modal", className)}>
      <Button
        className="button buttonPrimary buttonWithIcon sm-with-full"
        type="text"
        onClick={() => setIsModalVisible(true)}
        icon={<DownloadIcon />}
      >
        Vormerkungen
      </Button>
      <Modal
        title={<h3>Vormerkungen</h3>}
        wrapClassName={clsx("order-overview-modal-wrapper", wrapperClassName)}
        visible={isModalVisible}
        okText="Download"
        okButtonProps={{ disabled: !isFormValid, loading: isDownloading }}
        onOk={() => form.submit()}
        cancelText="Schließen"
        onCancel={() => setIsModalVisible(false)}
        width={600}
        closable
        keyboard={false}
        forceRender
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            dates: [startDate, endDate],
            categories: selectedCodes,
          }}
          onFinish={downloadOrderOverviewDocument}
          onFieldsChange={() => {
            setIsFormValid(
              !form.getFieldsError()?.find((field) => {
                return !!field.errors.length;
              })
            );
          }}
          autoComplete="off"
        >
          <p>
            Bitte wählen Sie unten aus, für welchen Zeitraum und für welche
            Sortimentskategorien Sie Ihre Vormerkungen als PDF-Datei
            herunterladen möchten:
          </p>
          <Form.Item
            name="dates"
            label="Zeitraum"
            className="labeledFormItem"
            required
            rules={[
              {
                required: true,
                message: "Bitte Zeitraum auswählen.",
              },
            ]}
          >
            <RangePicker
              onChange={(dates: [Moment, Moment]) => {
                setStartDate(dates?.[0] || moment());
                setEndDate(dates?.[1] || moment());
              }}
              format={fullDateFormat}
            />
          </Form.Item>
          <Form.Item
            name="categories"
            label="Sortimentskategorien"
            className="labeledFormItem"
            help={
              <div>
                Wenn Sie keine Kategorie auswählen, werden die Vormerkungen für
                alle Kategorien berücksichtigt.
              </div>
            }
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Bitte wählen Sie eine Kategorie"
              onChange={(value) => {
                setSelectedCodes(value);
              }}
              options={options}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              filterSort={(optionA, optionB) =>
                String(optionA?.label || "").localeCompare(
                  String(optionB?.label || "")
                )
              }
              filterOption={(inputValue: string, option) => {
                return String(option?.label || "")
                  .toLowerCase()
                  .includes(inputValue?.toLowerCase());
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OrderOverviewModal;
