import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Popover } from "antd";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { ReactComponent as BurgerMenu } from "../../static/svg/burger-menu.svg";
import { ReactComponent as Cross } from "../../static/svg/close.svg";
import { ReactComponent as UserIcon } from "../../static/svg/account.svg";
import { ReactComponent as Heart } from "../../static/svg/bookmark.svg";
import { ReactComponent as WeilingLogo } from "../../static/svg/weiling.svg";

import SearchInput from "../searchInput/SearchInput";
import FoldoutMenu from "./FoldoutMenu";
import CartLinkButton from "../cart/CartLinkButton";
import UserNavigation from "../molecules/UserNavigation";
import DeliveryDate from "../deliveryDate/DeliveryDate";
import BusinessUnitBox from "../atoms/BusinessUnitBox";
import { PricesSwitch } from "../molecules";
import { routePathNames } from "../../appConfig";
import { RootState } from "../../types/rootState";

function Header() {
  const [menu, setMenu] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);

  const { favourites } = useSelector(
    (state: RootState) => state?.favouriteList
  );

  const hasFavouriteList = !!favourites?.length;

  // todo refactor
  const handleMenu = () => {
    setMenu(!menu);

    const body = document.querySelector(".App");
    const search = document.querySelector(".header__search-mobile");
    const cart = document.querySelector(".header__cart");
    const mainnav = document.querySelector(".main-nav");

    if (search && cart && mainnav) {
      if (!menu) {
        body.classList.add("noscroll");
        search.classList.add("header__search-mobile-invisible");
        cart.classList.add("header__cart-mobile-invisible");
        mainnav.classList.add("main-nav-mobile-invisible");
      } else {
        body.classList.remove("noscroll");
        search.classList.remove("header__search-mobile-invisible");
        cart.classList.remove("header__cart-mobile-invisible");
        mainnav.classList.remove("main-nav-mobile-invisible");
      }
    }
  };

  return (
    <Layout.Header
      className="container-layout container-layout--header header header__sticky hidden-print"
      id="headerLayout"
    >
      <div className="header__wrapper">
        <Layout className="container-layout container-layout--inner">
          <div className="headerInner">
            <div className="headerMenu">
              {menu ? (
                <Cross className="burger-menu close-menu" />
              ) : (
                <div
                  role="button"
                  className="burger-menu-div"
                  onClick={handleMenu}
                  onKeyDown={handleMenu}
                  tabIndex={-1}
                >
                  <BurgerMenu className="burger-menu" />
                </div>
              )}
            </div>
            <Link to="/" className="header__logo-container">
              <WeilingLogo className="main-logo" />
            </Link>
            <SearchInput className="search__container" />
            <DeliveryDate className="date__container" />
            <BusinessUnitBox className="business-unit-box__container" />
            <PricesSwitch className="hidden-xs-down" />
            <div className="hidden-xs-down link--favoriteList">
              <Link to={routePathNames.favouriteList}>
                <Heart
                  className={clsx(
                    hasFavouriteList
                      ? "has-favorite-list"
                      : "has-no-favorite-list"
                  )}
                />
              </Link>
            </div>
            <div className="header__user-navigation-container hidden-xs-down">
              <div className="burger-user">
                <Popover
                  placement="topRight"
                  overlayClassName="user-navigation-popover"
                  destroyTooltipOnHide
                  content={
                    <UserNavigation
                      onListItemClick={() => setIsNavVisible(false)}
                    />
                  }
                  trigger="click"
                  visible={isNavVisible}
                  onVisibleChange={() => setIsNavVisible(!isNavVisible)}
                >
                  <UserIcon
                    className={clsx(
                      isNavVisible && "header__user-navigation--open"
                    )}
                  />
                </Popover>
              </div>
            </div>
            <div className="header__cart-wrapper">
              <CartLinkButton />
            </div>
          </div>

          {!!menu && <FoldoutMenu onClick={handleMenu} />}
        </Layout>
      </div>
    </Layout.Header>
  );
}

export default Header;
