const getWeekdayShipmentDateFullName = (
  weekdayShipmentDate: string
): string => {
  switch (weekdayShipmentDate) {
    case "Mo": {
      return "Montag";
    }
    case "Di": {
      return "Dienstag";
    }
    case "Mi": {
      return "Mittwoch";
    }
    case "Do": {
      return "Donnerstag";
    }
    case "Fr": {
      return "Freitag";
    }
    case "Sa": {
      return "Samstag";
    }
    case "So": {
      return "Sonntag";
    }
    default: {
      return "-";
    }
  }
};
export { getWeekdayShipmentDateFullName };
