import { AxiosResponse, CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import storeDispatch from "../../state/actions/storeDispatch";
import { updateProductOnPriceTagListEndpoint } from "../endpoints";
import { PriceTagListApi } from "../../types/userLists";

interface DeleteProductsFromPriceTagList {
  cancelTokenSource: CancelTokenSource;
  sku: PriceTagListApi["sku"];
  deleteSelected?: boolean;
}

/**
 * Remove products from the priceTagList by SKUs
 * @return {Promise<AxiosResponse>}
 */
const deleteProductsFromPriceTagList = ({
  cancelTokenSource,
  sku,
  deleteSelected = false,
}: DeleteProductsFromPriceTagList): Promise<AxiosResponse> =>
  axiosWithAuth()
    .delete(`${updateProductOnPriceTagListEndpoint}/multi`, {
      data: {
        data: {
          type: "tags",
          attributes: {
            skuList: [...sku],
          },
        },
      },
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (!response || response.status !== 204) {
        return Promise.reject(response);
      }

      const didDeleteAll = sku?.length === 1 && sku?.[0] === "*";

      if (didDeleteAll) {
        storeDispatch("priceTagList/reset-price-tag-list", {});
        return response;
      }

      if (deleteSelected) {
        storeDispatch("priceTagList/remove-selected-skus", sku);
        return response;
      }

      storeDispatch("priceTagList/remove-sku", sku);
      storeDispatch("priceTagList/delete-selected-priceTag-item", sku?.[0]);
      return response;
    });

export default deleteProductsFromPriceTagList;
