import React, { useRef, useState, useCallback, useEffect } from "react";
import { Input, AutoComplete, Spin, message } from "antd";
import { useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import requestCatchHandler from "../../../api/requestCatchHandler";
import ButtonAddToCart from "../../buttons/ButtonAddToCart";
import createSuggestions from "../../../utils/suggest/createSuggestions";
import { messageData } from "../../../appConfig";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getCartErrorMessage from "../../../utils/getCartErrorMessage";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import isItemInCart from "../../../utils/isItemInCart";
import useUpdateCartItemQuantity from "../../../hooks/useUpdateCartItemQuantity";

/**
 * quick order component with encapsulated logics
 * @constructor
 */
const QuickOrderInput: React.FC = () => {
  const updateCartItemQuantity = useUpdateCartItemQuantity();
  const { id: cartId, deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );
  const { cartItems } = useSelector((state: any) => state.currentCart);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [options, setOptions] = useState<null | any[]>(null);
  const [selectedItemSku, setSelectedItemSku] = useState("");
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [itemInCartQuantity, setItemInCartQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  /**
   * reset all states to initial values
   */
  const resetComponentStates = () => {
    inputRef?.current?.blur();
    buttonRef?.current?.blur();

    setSelectedItemSku("");
    setOptions(null);
    setButtonIsDisabled(true);
    setItemInCartQuantity(0);
  };

  /**
   * handler to add item to cart or display short hint that item is already in cart
   */
  const addItemToCart = (value?: any) => {
    const sku = typeof value === "string" ? value : String(selectedItemSku);

    if (!isItemInCart(cartItems, sku)) {
      setIsLoading(true);

      updateCartItemQuantity({
        deliveryDate,
        cartId,
        sku,
        quantity: 1,
        cancelTokenSource: cancelTokenSource.current,
      })
        .then((cartResponse) => {
          if (cartResponse.status > 201) {
            return Promise.reject(cartResponse);
          }

          resetComponentStates();

          const cartErrorMessage = getCartErrorMessage({
            response: cartResponse,
            sku,
          });

          if (cartErrorMessage?.reason) {
            message.error({
              content: cartErrorMessage.reason,
            });
          }

          setIsLoading(false);

          return cartResponse;
        })
        .catch((error) => {
          setIsLoading(false);

          if (!axios.isCancel(error)) {
            message.error({
              content:
                error?.response?.data?.errors[0]?.detail ||
                messageData.error.unexpected.content,
            });
          }

          requestCatchHandler(error);
        });
    }
  };

  /**
   * update badge count value
   */
  const updateItemCountBadge = useCallback(
    (value: string) => {
      const itemCartQuantity =
        cartItems.find((cartItem: any) => cartItem.sku === value)?.quantity ||
        0;

      setItemInCartQuantity(itemCartQuantity);
    },
    [cartItems]
  );

  /**
   * update input and button
   * @param {string} value
   */
  const updateComponentStates = (value: string) => {
    updateItemCountBadge(value);
    setSelectedItemSku(value);
  };

  /**
   * check if a sku is already in cart
   * @param {string} sku
   */
  const checkIfAlreadyOnList = (sku: string) => {
    return isItemInCart(cartItems, sku);
  };

  /**
   * input change handler
   * @param {string} value
   */
  const onSearch = (value: string) => {
    updateComponentStates(value);
    setButtonIsDisabled(true);

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    if (value.length >= 3) {
      // create suggestions and use created result for states
      createSuggestions({
        query: value,
        deliveryDate,
        cancelTokenSource: cancelTokenSource.current,
        checkIfAlreadyOnList,
        isAlreadyOnListWarning: "ist bereits im Warenkorb",
        type: "quickOrder",
      }).then((result: any) => {
        setOptions(result);
        /*
         * disable the button based on the following conditions:
         * if:
         * - all results are not available
         * - all entries do not match the input value
         * - the result length is exactly 1 and the product is in the current cart (not able to add again)
         */
        const isButtonDisabled = result
          ? result.every((resultEntry: any) => {
              return resultEntry.disabled || resultEntry.value !== value;
            }) ||
            (result.length === 1 && isItemInCart(cartItems, value))
          : true;

        // set button disabled if no value as input or the options array has only 1 or zero entries
        setButtonIsDisabled(isButtonDisabled);
      });
    }
  };

  /**
   * handler for click event if user selects entry
   * @param value {string}
   */
  const onSelect = (value: string) => {
    updateComponentStates(value);

    addItemToCart(value);
  };

  /**
   * make sure to update a maybe visible badge if the item gets increased in cart
   * event listener on scroll to close autocomplete list
   */
  useEffect(() => {
    const onScroll = () => {
      if (selectedItemSku.length > 0) {
        resetComponentStates();
      }
    };

    document.addEventListener("scroll", onScroll);

    updateItemCountBadge(selectedItemSku);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [updateItemCountBadge, selectedItemSku]);

  return (
    <div className="quickOrder" ref={containerRef}>
      {isLoading && <Spin size="small" className="quickOrderLoading" />}

      <AutoComplete
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        ref={inputRef}
        value={selectedItemSku}
        disabled={isLoading}
        open={selectedItemSku.length > 0 && isInputFocused}
        notFoundContent="Keine Produkte gefunden."
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        // set the minWidth to the parent container width or 240 to display the product name inside the dropdown
        dropdownStyle={{
          minWidth: containerRef.current?.clientWidth || 240,
        }}
      >
        <Input size="large" placeholder="Artikelnr." />
      </AutoComplete>

      <ButtonAddToCart
        isDisabled={buttonIsDisabled || isLoading}
        withBadge={itemInCartQuantity > 0}
        badgeCount={itemInCartQuantity}
        onClick={addItemToCart}
        ref={buttonRef}
      />
    </div>
  );
};

export default QuickOrderInput;
