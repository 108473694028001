import React from "react";
import { ReactComponent as WeilingLogo } from "../../../static/svg/weiling.svg";

const PrintViewBanner: React.FC = () => {
  return (
    <span className="print-view-banner">
      <WeilingLogo />
    </span>
  );
};

export default PrintViewBanner;
