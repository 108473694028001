import React, { useMemo, useState } from "react";

import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { Col, Result, Row } from "antd";
import queryString from "query-string";

import TrackingHelmet from "../../Matomo/TrackingHelmet";
import StandingOrdersTable from "./StandingOrdersTable";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import useGetDeliveryDate from "../../../hooks/useGetDeliveryDate";
import useGetStandingOrders from "../../../hooks/useGetStandingOrders";
import useUpdateUrlFragments from "../../../hooks/useUpdateUrlFragments";
import BackButton from "../../backButton/BackButton";
import HrDivider from "../../divider/Divider";
import { ContentBlock } from "../../atoms";
import {
  locationSearchQueryParameter,
  pageTitles,
  pageTitleSuffix,
} from "../../../appConfig";
import { StandingOrdersSorting } from "../../../types/standingOrder";

interface StandingOrderProps {
  className?: string;
}

const DEFAULT_SORTING: StandingOrdersSorting = "sku";

const StandingOrders: React.FC<StandingOrderProps> = (
  props: StandingOrderProps
) => {
  const { className } = props;

  const { search } = useLocation();
  const parsedSearch = useMemo(() => queryString.parse(search), [search]);
  const { [locationSearchQueryParameter.page]: pageQuery } = parsedSearch;

  const [page, setPage] = useState<number>(Number(pageQuery) || 1);
  const [sorting, setSorting] =
    useState<StandingOrdersSorting>(DEFAULT_SORTING);

  const [deliveryDate] = useGetDeliveryDate();
  const { isLoading, hasError, total, standingOrders } = useGetStandingOrders({
    deliveryDate,
    page,
    sort: sorting,
  });
  const setUpdateUrlFragments = useUpdateUrlFragments();

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    const { order, field } = sorter;

    if (order) {
      const dir = order === "descend" ? "-" : "";
      setSorting(`${dir}${field}` as StandingOrdersSorting);
    } else {
      setSorting(DEFAULT_SORTING);
    }
    if (pagination.current) {
      setPage(pagination.current);
      setUpdateUrlFragments({
        context: "pagination",
        parameters: {
          page: pagination.current,
        },
      });
    }
  };

  return (
    <>
      <TrackingHelmet
        title={pageTitles.standingOrder}
        suffix={pageTitleSuffix}
      />

      <ShopCategoryNavigation />

      <ContentBlock
        showHeader={false}
        className={clsx("standing-orders-page", className)}
      >
        <BackButton />

        <Row className="mb-m">
          <Col xs={12} md={8} className="flex items-center">
            <h1 className="text-truncate mb-0">Daueraufträge</h1>
            {total > 0 && <span className="ml-s">{total} Aufträge</span>}
          </Col>
        </Row>

        <HrDivider spacingTop="0" size={2} />

        <Row gutter={10} className="mb-m mt-m">
          <Col xs={12}>
            {!hasError && (
              <StandingOrdersTable
                standingOrders={standingOrders}
                total={total}
                page={page}
                isLoading={isLoading}
                emptyDescription={<span>Keine Daueraufträge vorhanden.</span>}
                onTableChange={onTableChange}
              />
            )}

            {!isLoading && !!hasError && (
              <Result
                status="warning"
                className="mb-3xl"
                title="Daueraufträge konnten nicht abgerufen werden."
              />
            )}
          </Col>
        </Row>
      </ContentBlock>
    </>
  );
};

export default StandingOrders;
