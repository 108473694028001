import React, { useRef, useState } from "react";

import { debounce } from "debounce";
import { useDispatch, useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../api/getCancelTokenSource";
import requestCatchHandler from "../../api/requestCatchHandler";
import updateProductsOnPriceTagList from "../../api/priceTagList/updateProductsOnPriceTagList";
import useCancelAxiosOnUnmount from "../../hooks/useCancelAxiosOnUnmount";
import ProductCount from "../product/ProductCount";
import { getPriceFromFormValue } from "../pages/Stocktaking/StocktakingForm/utils";
import { delays, messageData } from "../../appConfig";
import { ProductData } from "../../types/productData";
import { PriceTagListEntry } from "../../types/priceTagList";

interface PriceTagListPriceInputProps {
  sku: ProductData["sku"];
}

const PriceTagListPriceInput: React.FC<PriceTagListPriceInputProps> = (
  props: PriceTagListPriceInputProps
) => {
  const { sku } = props;

  const dispatch = useDispatch();

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const defaultValue = useSelector(
    (state: any) =>
      state.priceTagList.priceTags?.find(
        (entry: PriceTagListEntry) => entry.sku === sku
      )?.price || null
  );

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const onChange = debounce((value: string) => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    setIsUpdating(true);

    const price = value ? getPriceFromFormValue(Number(value)) : null;

    updateProductsOnPriceTagList({
      priceTags: [{ sku, price }],
      cancelTokenSource: cancelTokenSource.current,
    })
      .then(() => {
        setIsUpdating(false);
        dispatch({
          type: "priceTagList/add-price-to-sku",
          payload: {
            sku,
            price,
          },
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsUpdating(false);
          setAlertMessage(messageData.error.priceTagList.updatePrice.content);
        }
        requestCatchHandler(error);
      });
  }, delays.updatePriceTagProduct);

  return (
    <ProductCount
      containerClassName="priceTagListPriceInput"
      controls={false}
      decimalSeparator=","
      handleChange={onChange}
      isLoading={isUpdating}
      min={0.01}
      max={999.99}
      maxLength={null}
      precision={2}
      stringMode
      value={defaultValue ? parseInt(defaultValue, 10) / 100 : null}
      alertMessage={alertMessage}
      setAlertMessage={setAlertMessage}
    />
  );
};

export default PriceTagListPriceInput;
