import { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import axios from "axios";
import { Entry } from "contentful";

import getPortalTip from "../api/contentful/getPortalTip";
import handleError from "../utils/handleError";
import { ContentfulPortalTipEntry } from "../types/contentfulPortalTipEntry";
import { StockType } from "../types/stock";
import { RootState } from "../types/rootState";

interface GetPortalTipProps {
  shouldFilterByDates?: boolean;
  limit?: number;
  skip?: number;
  stockName?: StockType;
}

const useGetPortalTip = (props: GetPortalTipProps) => {
  const { shouldFilterByDates, limit, skip, stockName } = props;

  const { showPortalTips = true } =
    useSelector((state: RootState) => state?.userData) || {};

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<Entry<ContentfulPortalTipEntry>[]>([]);
  const [total, setTotal] = useState<number>(null);

  const fetchPortalTip = useCallback(async () => {
    setIsLoading(true);

    getPortalTip({
      shouldFilterByDates,
      limit,
      skip,
      stockName,
    })
      .then((response) => {
        const { items = [] } = response;
        setEntries(items);
        setTotal(items?.length || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [shouldFilterByDates, limit, skip, stockName]);

  useEffect(() => {
    if (!showPortalTips) {
      return;
    }

    fetchPortalTip().then();
  }, [showPortalTips, fetchPortalTip]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetPortalTip;
