import { search } from "../../types/search";

const initialState: search = {
  searchQuery: "",
};

const searchReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "search/set-search-query": {
      const newSearchQuery =
        payload && Object.values(payload)?.length > 0 ? payload : "";

      return {
        ...state,
        searchQuery: newSearchQuery,
      };
    }

    case "search/delete-search-query": {
      return {
        ...state,
        searchQuery: "",
      };
    }

    default:
      return state;
  }
};

export default searchReducer;
