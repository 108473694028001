import { Entry } from "contentful";
import moment from "moment/moment";

import getContentfulContentType from "./getContentfulContentType";
import stockNameMapping from "../../utils/stockNameMapping";
import { contentfulContentTypes } from "../../appConfig";
import { contentfulDateTimeFormat } from "../../utils/dateFormats";
import { StockType } from "../../types/stock";
import { ContentfulPortalTipEntry } from "../../types/contentfulPortalTipEntry";

interface PortalTipParams {
  shouldFilterByDates?: boolean;
  limit?: number;
  skip?: number;
  withTotal?: boolean;
  stockName?: StockType;
}

interface PortalTipResponse {
  items: Entry<ContentfulPortalTipEntry>[];
  total: number;
}

const getPortalTip = async ({
  shouldFilterByDates = true,
  limit = 1,
  skip = 0,
  withTotal = true,
  stockName = "HL",
}: PortalTipParams): Promise<PortalTipResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.portalTip,
    limit,
    skip,
    order: "-fields.publish_date,-sys.updatedAt",
    inclusion: {
      ...(shouldFilterByDates && {
        "fields.publish_date[lte]": moment().format(contentfulDateTimeFormat),
        "fields.unpublish_date[gte]": moment().format(contentfulDateTimeFormat),
      }),
      "fields.stock_name[in]": `Alle,${stockNameMapping?.[stockName] || ""}`,
    },
    withTotal,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }

    const { items = [], total } = response;

    return {
      items,
      total,
    } as PortalTipResponse;
  });

export default getPortalTip;
